import { NSI_DICTIONARIES } from '@/vue_present/Reuse/Lists/NSI/nsiDictionaries'

const addNsiCodeToTitle = (item) => {
  item.title = `${item.nsiId} ${item.title}`
}

export const listAdapter = {
  toClient (response, { permittedValues, dictionary }) {
    if (permittedValues) {
      response.data = response.data
        .filter((item) => permittedValues.includes(item.title))
    }

    if (dictionary === NSI_DICTIONARIES.MKB10) {
      response.data.forEach(addNsiCodeToTitle)
    }

    return response
  },
}
