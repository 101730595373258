import ContactsPopover from '@/vue_apps/Contacts/ContactsPopover/ContactsPopover.vue'
import { createVueApp } from '@/vue_components/create_vue_app'

export const phonePopover = {
  init () {
    return createVueApp({
      el: '#phone_popover',
      name: 'ContactsPopoverRoot',
      render: (h) => h(ContactsPopover),
    })
  },
}
