import BaseFormatter from './BaseFormatter'

export default class DateFormatter extends BaseFormatter {
  localDate (date) {
    if (!date) {
      return date
    }

    return moment(date).format(gon.localization.date_format.toUpperCase())
  }

  clinicDate (date) {
    if (!date) { return date }
    const clinicDate = Utils.getTimezonedMoment(date)

    return this.localDate(clinicDate)
  }

  localTime (date) {
    if (!date) {
      return date
    }

    return moment(date).format('HH:mm')
  }

  agePostfix (years) {
    if (gon.localization.locale !== 'ru') {
      return 'years'
    }

    const yearsString = years.toString()

    // to prevent casting string to number every time
    const wordMap = {
      '11$': 'лет',
      '12$': 'лет',
      '13$': 'лет',
      '14$': 'лет',
      '15$': 'лет',
      '16$': 'лет',
      '17$': 'лет',
      '18$': 'лет',
      '19$': 'лет',
      '0$': 'лет',
      '1$': 'год',
      '2$': 'года',
      '3$': 'года',
      '4$': 'года',
      '5$': 'лет',
      '6$': 'лет',
      '7$': 'лет',
      '8$': 'лет',
      '9$': 'лет',
    }
    const keys = Object.keys(wordMap)
    for (const key of keys) {
      if (yearsString.match(key)) {
        return wordMap[key]
      }
    }
  }

  utcDateTime (datetime) {
    if (!datetime) {
      return datetime
    }

    return moment.utc(datetime).format(`${gon.localization.date_format.toUpperCase()}, HH:mm`)
  }

  /**
   *
   * @param {number} time
   */
  duration (time) {
    return moment(moment.duration(time * 1000)._data).format('mm:ss')
  }

  /**
   * @param {Date|string|null} date Дата может быть строкой только если указан sourceFormat
   * @param {string} [sourceFormat] Формат, в котором находится дата
   * @return {string|null}
   */
  rubyDate (date, sourceFormat) {
    date = date && sourceFormat ? moment(date, sourceFormat).toDate() : date

    return date
      ? Utils.dateJStoRuby(date)
      : date
  }
}
DateFormatter.namespace = 'date'
