import { SemdDatePicker } from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/SemdDatePicker/SemdDatePicker'

export const notifyWhenNotValid = (elementStart: HTMLElement, elementEnd: HTMLElement, errorMessageOrValid: string | boolean) => {
  const isValid = typeof errorMessageOrValid === 'boolean'

  isValid
    ? elementStart.classList.remove('red')
    : elementStart.classList.add('red')

  isValid
    ? elementEnd.classList.remove('red')
    : elementEnd.classList.add('red')

  if (!isValid) {
    Notificator.warning(errorMessageOrValid)
  }
}

type TSemdDatePickerLike = SemdDatePicker | { value: string }
export const getDatesErrorMessageOrValid = (dateStart: TSemdDatePickerLike, dateEnd: TSemdDatePickerLike): string | boolean => {
  const dateStartLessOrEqualToday =
    Utils.getTimezonedMoment(dateStart.value).isBefore(Utils.getTimezonedMoment()) || Utils.getTimezonedMoment(dateStart.value).isSame(Utils.getTimezonedMoment())

  if (!dateEnd.value) {
    return dateStartLessOrEqualToday || t('semds.validationErrors.dateStartAfterToday')
  }

  const dateEndLessOrEqualToday =
    Utils.getTimezonedMoment(dateEnd.value).isBefore(Utils.getTimezonedMoment()) || Utils.getTimezonedMoment(dateEnd.value).isSame(Utils.getTimezonedMoment())

  if (!dateStartLessOrEqualToday || !dateEndLessOrEqualToday) {
    return t('semds.validationErrors.datesAfterToday')
  }

  const dateStartLessOrEqualDateEnd =
    moment(dateStart.value).isBefore(dateEnd.value) || moment(dateStart.value).isSame(dateEnd.value)

  if (!dateStartLessOrEqualDateEnd) {
    return t('semds.validationErrors.dateStartBeforeDateEnd')
  }

  return true
}
