<template>
  <panel-heading
    :title="`${t('info_company')} - ${t('orders_registries')}`"
    icon="fad fa-cabinet-filing"
    class="company-router-container"
  >
    <div class="company-orders-registry">
      <div :class="classBox">
        <panel-heading-collapsed
          v-if="displayMedium"
          :show="!showWindowState.unassociated"
          :title="t('orders_without_registry')"
          :count="fetched.orders.unassociated.list.length"
          icon="fad fa-cabinet-filing"
          class="orders-registry-collapsed"
          direction="down"
          @panel-collapsed-toggle="collapsedUnassociated"
        />

        <panel-heading-collapsed
          v-else
          :show="!showWindowState.unassociated"
          :title="t('orders_without_registry')"
          :count="fetched.orders.unassociated.list.length"
          direction="right"
          class="panel-inner"
          @panel-collapsed-toggle="collapsedUnassociated"
        />

        <div
          v-if="showWindowState.unassociated"
          class="unassociated-orders"
        >
          <panel-heading
            :title="t('orders_without_registry')"
            :count="fetched.orders.unassociated.list.length"
            class="panel-inner"
            icon="fad fa-cabinet-filing"
          >
            <template #buttons>
              <span
                :class="['fad', {'fa-angle-up': displayMedium}, {'fa-angle-left': !displayMedium}]"
                @click="rollUpUnassociatedOrders"
              />
            </template>
            <company-orders-registry-workspace
              :orders-loading="loadingUnassociatedOrders"
              :filter-state.sync="fetched.orders.unassociated.input"
              :display-tablet="displayMedium"
              @reset-default-filters="fetched.orders.unassociated.input = newOrdersDefaultInput()"
            >
              <si-table
                :spinner="loadingUnassociatedOrders"
                :not-found="fetched.orders.unassociated.list.length === 0"
                :error="fetched.orders.unassociated.error"
              >
                <template #header>
                  <si-field
                    :checked="
                      fetched.orders.unassociated.list.length > 0 &&
                        objArrEqMap(
                          fetched.orders.unassociated.list,
                          fetched.orders.unassociated.input.ordersMap
                        )
                    "
                    @checkbox-change="
                      $event.target.checked
                        ? ordersToMap(
                          fetched.orders.unassociated.input.ordersMap,
                          fetched.orders.unassociated.list
                        )
                        : fetched.orders.unassociated.input.ordersMap = Object.create(null)
                    "
                  >
                    <div class="number">
                      {{ t('common.number') }}
                    </div>

                    <div class="date">
                      {{ t('common.date') }}
                    </div>

                    <div class="title">
                      {{ t('client') }}
                    </div>

                    <div class="paid-status">
                      {{ t('status') }}
                    </div>

                    <div class="final-sum">
                      {{ t('to_pay') }}
                    </div>

                    <div class="paid-sum">
                      {{ t('made_payment_by_bill') }}
                    </div>
                  </si-field>
                </template>
                <template #body>
                  <si-field
                    v-for="order in fetched.orders.unassociated.sliced"
                    :key="order.id"
                    :checked="order.id in fetched.orders.unassociated.input.ordersMap"
                    @checkbox-change="
                      $event.target.checked
                        ? $set(fetched.orders.unassociated.input.ordersMap, order.id, order)
                        : $delete(fetched.orders.unassociated.input.ordersMap, order.id)
                    "
                  >
                    <div class="number">
                      <a
                        :href="$routes.order_path(order.id)"
                        target="_blank"
                      >
                        {{ order.id }}
                      </a>
                    </div>

                    <div
                      class="date"
                      :title="$filters.date(order.date)"
                    >
                      {{ $filters.date(order.date) }}
                    </div>

                    <div
                      class="title"
                      :title="$filters.shortName(order.client)"
                    >
                      <a
                        v-if="order.client && order.client.id"
                        :href="$routes.client_path(order.client.id)"
                        :class="{'deleted': order.client.deleted_at}"
                        target="_blank"
                      >
                        {{ $filters.shortName(order.client) }}
                      </a>
                    </div>

                    <div
                      class="paid-status"
                      :title="t(`_order_statuses.${order.paid_status}`)"
                    >
                      <span :class="ORDER_STATUS_CLASSES[order.paid_status && order.paid_status.toUpperCase()]">
                        {{ t(`_order_statuses.${order.paid_status}`) }}
                      </span>
                    </div>

                    <div class="final-sum">
                      {{ $filters.currency(order.final_sum) }}
                    </div>

                    <div class="paid-sum">
                      {{ $filters.currency(order.paid_sum) }}
                    </div>
                  </si-field>
                </template>
              </si-table>
              <si-footer>
                <pagination
                  layout="prev, pager, next"
                  :page-size="PAGINATION_PAGE_SIZE"
                  :pager-count="5"
                  :page-count="paginationUnassociatedPageCount"
                  :current-page.sync="fetched.orders.unassociated.pagination.currentPage"
                />
                <div
                  v-if="displayMedium && showWindowState.unassociated &&
                    showWindowState.associated"
                  class="order-transfer-manager order-transfer-manager--medium"
                >
                  <button
                    :disabled="mapEmpty(fetched.orders.unassociated.input.ordersMap)"
                    :title="t('add_to_registry')"
                    class="btn btn-primary"
                    @click="associateOrders"
                  >
                    <span class="fad fa-caret-right" />
                  </button>

                  <button
                    :disabled="mapEmpty(fetched.orders.associated.input.ordersMap)"
                    :title="t('take_from_registry')"
                    class="btn btn-primary"
                    @click="unassociateOrders"
                  >
                    <span class="fad fa-caret-left" />
                  </button>
                </div>
              </si-footer>
            </company-orders-registry-workspace>
          </panel-heading>
        </div>

        <div
          v-if="!displayMedium && showWindowState.unassociated &&
            showWindowState.associated"
          class="order-transfer-manager"
        >
          <button
            :disabled="mapEmpty(fetched.orders.unassociated.input.ordersMap)"
            :title="t('add_to_registry')"
            class="btn btn-primary"
            @click="associateOrders"
          >
            <span class="fad fa-caret-right" />
          </button>

          <button
            :disabled="mapEmpty(fetched.orders.associated.input.ordersMap)"
            :title="t('take_from_registry')"
            class="btn btn-primary"
            @click="unassociateOrders"
          >
            <span class="fad fa-caret-left" />
          </button>
        </div>

        <div
          v-if="showWindowState.associated"
          class="associated-orders"
        >
          <panel-heading
            :title="`${t('registry')} - ${fetched.ordersRegistry.data.title}`"
            class="panel-inner"
            icon="fad fa-cabinet-filing"
          >
            <template #buttons>
              <span
                :class="['fad', {'fa-angle-up': displayMedium}, {'fa-angle-left': !displayMedium}]"
                @click="rollUpAssociatedOrders"
              />
            </template>
            <company-orders-registry-workspace
              :orders-loading="loadingAssociatedOrders"
              :filter-state.sync="fetched.orders.associated.input"
              :display-tablet="displayMedium"
              @reset-default-filters="fetched.orders.associated.input = newOrdersDefaultInput()"
            >
              <si-table
                :spinner="loadingAssociatedOrders"
                :not-found="fetched.orders.associated.list.length === 0"
                :error="fetched.orders.associated.error"
              >
                <template #header>
                  <si-field
                    :checked="
                      fetched.orders.associated.list.length > 0 &&
                        objArrEqMap(
                          fetched.orders.associated.list,
                          fetched.orders.associated.input.ordersMap
                        )
                    "
                    @checkbox-change="
                      $event.target.checked
                        ? ordersToMap(
                          fetched.orders.associated.input.ordersMap,
                          fetched.orders.associated.list
                        )
                        : fetched.orders.associated.input.ordersMap = Object.create(null)
                    "
                  >
                    <div class="number">
                      {{ t('common.number') }}
                    </div>
                    <div class="date">
                      {{ t('common.date') }}
                    </div>
                    <div class="title">
                      {{ t('client') }}
                    </div>
                    <div class="paid-status">
                      {{ t('status') }}
                    </div>
                    <div class="final-sum">
                      {{ t('to_pay') }}
                    </div>
                    <div class="paid-sum">
                      {{ t('made_payment_by_bill') }}
                    </div>
                  </si-field>
                </template>
                <template #body>
                  <si-field
                    v-for="order in fetched.orders.associated.sliced"
                    :key="order.id"
                    :checked="order.id in fetched.orders.associated.input.ordersMap"
                    @checkbox-change="
                      $event.target.checked
                        ? $set(fetched.orders.associated.input.ordersMap, order.id, order)
                        : $delete(fetched.orders.associated.input.ordersMap, order.id)
                    "
                  >
                    <div class="number">
                      <a
                        :href="$routes.order_path(order.id)"
                        target="_blank"
                      >
                        {{ order.id }}
                      </a>
                    </div>
                    <div
                      class="date"
                      :title="$filters.date(order.date)"
                    >
                      {{ $filters.date(order.date) }}
                    </div>
                    <div
                      class="title"
                      :title="$filters.shortName(order.client)"
                    >
                      <a
                        v-if="order.client && order.client.id"
                        :href="$routes.client_path(order.client.id)"
                        :class="{'deleted': order.client.deleted_at}"
                        target="_blank"
                      >
                        {{ $filters.shortName(order.client) }}
                      </a>
                    </div>
                    <div
                      class="paid-status"
                      :title="t(`_order_statuses.${order.paid_status}`)"
                    >
                      <span :class="ORDER_STATUS_CLASSES[order.paid_status && order.paid_status.toUpperCase()]">
                        {{ t(`_order_statuses.${order.paid_status}`) }}
                      </span>
                    </div>
                    <div class="final-sum">
                      {{ $filters.currency(order.final_sum) }}
                    </div>
                    <div class="paid-sum">
                      {{ $filters.currency(order.paid_sum) }}
                    </div>
                  </si-field>
                </template>
              </si-table>
              <si-footer>
                <pagination
                  layout="prev, pager, next"
                  :page-size="PAGINATION_PAGE_SIZE"
                  :pager-count="5"
                  :page-count="paginationAssociatedPageCount"
                  :current-page.sync="fetched.orders.associated.pagination.currentPage"
                />
                <div class="registry-orders-action">
                  <div class="registry-orders-sum">
                    {{ t('to_pay') }} <span>{{ $filters.currency(ordersSumToPay) }}</span>
                  </div>
                  <button
                    class="btn btn-warning btn-sm hidden-print btn-with-icon pay-btn"
                    :disabled="disabledModalPay"
                    @click="modal.masspay.visibility = true; credit = sumToPay"
                  >
                    <span class="btn-with-icon_icon fad fa-money-check-edit" />
                    <span class="btn-with-icon_text">
                      {{ t('pay') }}
                    </span>
                  </button>
                </div>
              </si-footer>
            </company-orders-registry-workspace>
          </panel-heading>
        </div>

        <panel-heading-collapsed
          v-if="displayMedium"
          :show="!showWindowState.associated"
          :title="`${t('registry')} - ${fetched.ordersRegistry.data.title}`"
          icon="fad fa-cabinet-filing"
          class="orders-registry-collapsed"
          direction="down"
          @panel-collapsed-toggle="collapsedAssociated"
        />
        <panel-heading-collapsed
          v-else
          :show="!showWindowState.associated"
          :title="`${t('registry')} - ${fetched.ordersRegistry.data.title}`"
          direction="right"
          class="panel-inner registry-collapsed-right"
          @panel-collapsed-toggle="collapsedAssociated"
        />
      </div>
    </div>

    <company-orders-registry-masspay-modal
      :visibility="modal.masspay.visibility"
      :loading="modal.masspay.loading"
      :disabled="modal.masspay.disabled"
      :customer="customer"
      :payment-spec.sync="modal.masspay.form.paymentSpec"
      :sum-to-pay="sumToPay"
      :balance="balance"
      :summary-input-user.sync="summaryInputUser"
      :credit="modal.masspay.form.credit"
      :surrender.sync="modal.masspay.form.surrender"
      :deposit.sync="modal.masspay.form.deposit"
      :comment.sync="modal.masspay.form.comment"
      :validation-messages="modal.masspay.validationMessages"
      @close-modal-masspay="closeModalMasspay"
      @submit-form="createPayments"
    />

    <alert-modal
      :modal-visibility="modal.masspay.alert.visibility"
      :header-message="modal.masspay.alert.header"
      :message="alertMessage"
      @ok="alertClose"
      @close="alertClose"
    />
  </panel-heading>
</template>

<script>
import AlertModal from '@/vue_components/alert_modal.vue'
import CompanyOrdersRegistryWorkspace from './company_orders_registry_workspace.vue'
import CompanyOrdersRegistryMasspayModal from './company_orders_registry_masspay_modal.vue'
import PanelHeading from '@/vue_components/common/panel_heading.vue'
import PanelHeadingCollapsed from '@/vue_components/common/panel_heading_collapsed.vue'
import Pagination from '@/vue_components/common/pagination.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'

import watchers from './company_orders_registry_watchers.js'
import methods from './company_orders_registry_methods.js'

import { mapGetters } from 'vuex'
import CurrencyFormatter from '@/lib/currency_formatter/currency_formatter.js'
import { DATE_ISO_FORMAT, ORDER_STATUS_CLASSES, PAYMENT_SPECIES, STORAGE_KEY } from '../consts.js'
import { PAGINATION_PAGE_SIZE } from '@/vue_components/sort_items/consts.js'
import { ORDER_PAID_STATUS, PAYMENT_ERRORS } from '@/lib/payments/consts.js'
import { generateUID, getCurrentStorage } from '../../helpers/storage.js'

export default {
  name: 'CompanyOrdersRegistry',

  components: {
    AlertModal,
    CompanyOrdersRegistryWorkspace,
    CompanyOrdersRegistryMasspayModal,
    PanelHeading,
    PanelHeadingCollapsed,
    Pagination,
    SiTable,
    SiField,
    SiFooter,
  },

  data () {
    const uid = generateUID(this.$store.getters.GET_APP_CONF_CURRENT_USER_ID, this.$route.params.companyId)
    const localStoreCompany = getCurrentStorage(STORAGE_KEY, uid)
    const localStore = localStoreCompany[this.$route.params.registryId]
    const showWindowState = { associated: true, unassociated: false }
    const companyInfo = { title: '...' }

    if (localStore) {
      if (localStore.showWindow) {
        const { associated, unassociated } = localStore.showWindow

        showWindowState.associated = associated
        showWindowState.unassociated = unassociated
      }
    }

    if (localStoreCompany.companyInfo) {
      companyInfo.title = localStoreCompany.companyInfo.title
    }

    return {
      displayMedium: false,
      customer: companyInfo.title,
      showWindowState,
      fetched: {
        ordersRegistry: {
          data: {
            title: '',
            active_orders: [],
          },
          loading: false,
          error: false,
        },
        orders: {
          associated: {
            list: [],
            sliced: [],
            error: false,
            loading: false,
            updating: false,
            input: { ...this.newOrdersDefaultInput() },
            pagination: {
              currentPage: 1,
            },
          },
          unassociated: {
            list: [],
            sliced: [],
            error: false,
            loading: false,
            updating: false,
            input: { ...this.newOrdersDefaultInput() },
            pagination: {
              currentPage: 1,
            },
          },
        },
      },
      modal: {
        masspay: {
          visibility: false,
          loading: false,
          disabled: false,
          form: { ...this.defaultForm() },
          deficitPay: 0,
          surplusPay: 0,
          alert: { visibility: false, header: t('order_error_pay') },
          validationMessages: { ...this.defaultValidationForm() },
        },
      },
      PAGINATION_PAGE_SIZE,
      ORDER_STATUS_CLASSES,
    }
  },

  computed: {
    ...mapGetters([
      'GET_LOCALIZATION_CURRENCY_PARAMS',
      'GET_COMPANY_LOADING',
      'GET_APP_CONF_CURRENT_CLINIC_ID',
      'GET_APP_CONF_CURRENT_USER_ID',
      'GET_COMPANY_FINANCE',
    ]),

    currentStorage () {
      return generateUID(this.$store.getters.GET_APP_CONF_CURRENT_USER_ID, this.$route.params.companyId)
    },

    classBox () {
      const c = ['orders-box']
      if (
        this.showWindowState.unassociated &&
        this.showWindowState.associated
      ) c.push('orders-box--twins')

      return c
    },

    unassociatedOrdersFilter () {
      const {
        selectedOrderId,
        selectedDateRange,
        selectedOrderPaidStatus,
      } = this.fetched.orders.unassociated.input

      if (selectedOrderId) return { id_pattern: selectedOrderId }

      let dateParams = {
        start_date: undefined,
        end_date: undefined,
      }

      if (Array.isArray(selectedDateRange)) {
        const dateRange = selectedDateRange.map((date) => moment(date).format(DATE_ISO_FORMAT))
        dateParams = {
          start_date: !dateRange.length ? undefined : dateRange[0],
          end_date: !dateRange.length ? undefined : dateRange[1],
        }
      }

      return {
        ...dateParams,
        paid_status: selectedOrderPaidStatus === 'all' ? undefined : selectedOrderPaidStatus,
      }
    },

    associatedOrdersFilter () {
      const {
        selectedOrderId,
        selectedDateRange,
        selectedOrderPaidStatus,
      } = this.fetched.orders.associated.input

      if (selectedOrderId) return { id_pattern: selectedOrderId }

      let dateParams = {
        start_date: undefined,
        end_date: undefined,
      }

      if (Array.isArray(selectedDateRange)) {
        const dateRange = selectedDateRange.map((date) => moment(date).format(DATE_ISO_FORMAT))
        dateParams = {
          start_date: !dateRange.length ? undefined : dateRange[0],
          end_date: !dateRange.length ? undefined : dateRange[1],
        }
      }

      return {
        ...dateParams,
        paid_status: selectedOrderPaidStatus === 'all' ? undefined : selectedOrderPaidStatus,
      }
    },

    unassociatedOrdersReqParams () {
      return {
        ...this.unassociatedOrdersFilter,
        orders_registry_id: null,
      }
    },

    associatedOrdersReqParams () {
      return {
        ...this.associatedOrdersFilter,
        orders_registry_id: this.$route.params.registryId,
      }
    },

    loadingUnassociatedOrders () {
      return this.GET_COMPANY_LOADING ||
        this.fetched.orders.unassociated.loading ||
        this.fetched.orders.unassociated.updating
    },

    loadingAssociatedOrders () {
      return this.GET_COMPANY_LOADING ||
        this.fetched.orders.associated.loading ||
        this.fetched.orders.associated.updating
    },

    paginationAssociatedPageCount () {
      return Math.ceil(this.fetched.orders.associated.list.length / PAGINATION_PAGE_SIZE)
    },

    paginationUnassociatedPageCount () {
      return Math.ceil(this.fetched.orders.unassociated.list.length / PAGINATION_PAGE_SIZE)
    },

    selectedAssociatedOrderIds () {
      return Object.keys(this.fetched.orders.associated.input.ordersMap)
        .map((id) => parseInt(id))
    },

    selectedUnassociatedOrderIds () {
      return Object.keys(this.fetched.orders.unassociated.input.ordersMap)
        .map((id) => parseInt(id))
    },

    selectedAssociatedOrdersNum () {
      return this.selectedAssociatedOrderIds.length
    },

    selectedUnassociatedOrdersNum () {
      return this.selectedUnassociatedOrderIds.length
    },

    selectedAssociatedOrdersArray () {
      return this.selectedAssociatedOrderIds
        .map((orderId) => this.fetched.orders.associated.input.ordersMap[orderId])
    },

    selectedOrdersArrayToPay () {
      return this.selectedAssociatedOrdersArray.filter((order) =>
        order.paid_status === ORDER_PAID_STATUS.NOT_PAID ||
        order.paid_status === ORDER_PAID_STATUS.PAID_CREDIT)
    },

    balance () {
      return parseFloat(this.GET_COMPANY_FINANCE)
    },

    paymentSpec () {
      return this.modal.masspay.form.paymentSpec[0]
    },

    ordersSumToPay () {
      return Object.values(this.fetched.orders.associated.input.ordersMap)
        .reduce((sum, order) => {
          let unpaid = parseFloat(order.unpaid_sum)

          if (Math.sign(unpaid) === -1) unpaid = 0

          return sum + unpaid
        }, 0)
    },

    sumToPay () {
      return parseFloat(this.ordersSumToPay)
    },

    sumToPayCurrency () {
      const formatter = new CurrencyFormatter(
        this.GET_LOCALIZATION_CURRENCY_PARAMS.code,
        this.GET_LOCALIZATION_CURRENCY_PARAMS.locale
      )

      return formatter.format(this.sumToPay)
    },

    disabledModalPay () {
      return this.fetched.orders.associated.input.ordersMap.length < 0 || this.sumToPay <= 0
    },

    alertMessage () {
      const { ordersErrorValid, ordersErrorCodes } = this.modal.masspay.validationMessages
      const messages = []

      ordersErrorValid.forEach((order) => {
        for (const [id, errors] of Object.entries(order)) {
          messages.push(`${t('order_with_number')} ${id} - ${errors.join(', \n')}`)
        }
      })

      if (ordersErrorCodes.includes(PAYMENT_ERRORS.TYPES.CLIENT.ORDER_IN_CREDIT.CODE)) {
        messages.push(`${t('add_missing_amount')} ${this.sumToPayCurrency}`)
      }

      return messages.join(' \n')
    },

    hasValidationOrderErrors () {
      return this.modal.masspay.validationMessages.ordersErrorValid.length !== 0
    },

    summaryInputUser: {
      get () {
        return this.modal.masspay.form.summary
      },

      set (value) {
        this.modal.masspay.form.summary = parseFloat(value)
      },
    },

    surrender: {
      get () {
        return this.modal.masspay.form.surrender
      },
      set (value) {
        this.modal.masspay.form.surrender = parseFloat(value)
        if (this.paymentSpec === PAYMENT_SPECIES.BY_CASH) {
          this.deposit = 0
        }
      },
    },

    credit: {
      get () {
        return this.modal.masspay.form.credit
      },
      set (value) {
        this.modal.masspay.form.credit = parseFloat(value)
      },
    },

    deposit: {
      get () {
        return this.modal.masspay.form.deposit
      },
      set (value) {
        this.modal.masspay.form.deposit = parseFloat(value)
      },
    },
  },

  watch: { ...watchers },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },

  mounted () {
    this.fetchOrdersRegistry()
    this.fetchOrders()
    this.onResize()
  },

  created () {
    this.LOAD_COMPANY(this.$route.params.companyId)
    window.addEventListener('resize', this.onResize)
  },

  methods,
}
</script>
