import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { findSemdState, SEMD_STATE_ENUM } from '@/vue_apps/Semds/SemdModal/const/const.js'
import { SemdsUtils } from '@/vue_apps/Semds/entities/SemdsUtils'

export class EgiszSemdListItem extends MListServiceItem {
  /**
   * @param {{
   *     id: number,
   *     title: string,
   *     state: string,
   *     version: number,
   *     date: string,
   *     signed: boolean,
   *     client: { id: number, fullName: string },
   *     semdType: string,
   * }} data
   */
  constructor (data) {
    super()
    this.id = data.id

    this.rawStatus = data.state

    this.rawSemdType = SemdsUtils.extractSemdType(data.semdType)

    this.number = new MTableCell(data.id)

    this.date = new MTableCell(vueFilters.date(data.date))

    this.title = new MTableCell(data.title)
      .addTooltip(true)
      .addCut()

    if (data.client) {
      this.client = new MTableCell(data.client.fullName)
        .addCut()
        .addTooltip(true)
        .addClasses(data.client.deletedAt ? 'deleted' : '')
        .addTypeUrl(Routes.client_path(data.client.id))
    }

    this.semdType = new MTableCell(t(data.semdType, { scope: 'semds.semdType' }) || '-')
      .addTooltip(true)
      .addCut()

    this.setStatus(data.state)
    this.setSigned(data.signed)
  }

  get isDeletable () {
    return this.__deletable
  }

  get isSignable () {
    return this.status.value === SEMD_STATE_ENUM.NEED_CLINIC_SIGNATURE
  }

  setStatus (status) {
    const { icon, color, title } = findSemdState(status)
    this.status = new MTableCell(status)
      .addTooltip(title)
      .addTypeIcon(icon)
      .addClasses(color)

    this.__deletable = [
      SEMD_STATE_ENUM.NEED_DOCTOR_SIGNATURE,
      SEMD_STATE_ENUM.NEED_CLINIC_SIGNATURE,
      SEMD_STATE_ENUM.READY_TO_BE_SENT,
      SEMD_STATE_ENUM.SENDING_ERROR,
    ].includes(status)

    this.rawStatus = status

    this.__notDeletableReason = this.__deletable && t('egisz.semds.notDeleteTooltip')
  }

  setSigned (signed) {
    this.signed = new MTableCell(signed)
      .addTypeIcon(signed ? 'signed' : 'not-signed')
  }
}
