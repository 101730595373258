import AbstractFormatter from '@/lib/formatters/AbstractFormatter'
import { isArray } from '@/helpers/typeofHelpers'

export default class UserFormatter extends AbstractFormatter {
  name (doctor) {
    if (!doctor) return ''
    const { name, surname, secondName } = this.parseFio(doctor)

    return [
      surname,
      name ? name.charAt(0).toUpperCase() + '.' : '',
      secondName ? secondName.charAt(0).toUpperCase() + '.' : '',
    ]
      .filter(Boolean)
      .join(' ')
  }

  /**
   * Алиас для name
   * @param doctor
   * @return {string|string}
   */
  shortName (doctor) { return this.name(doctor) }

  fullName (doctor) {
    if (!doctor) return ''
    const { name, surname, secondName } = this.parseFio(doctor)

    return [surname, name, secondName].join(' ').trim()
  }

  specialties (doctor) {
    if (!doctor) return ''

    return doctor.specialties.map((specialty) => specialty.title).join(', ')
  }

  /**
   * @private
   * @paramuser - {Array, Object, String}
   */
  parseFio (user) {
    if (isArray(user)) {
      return this.parseFioArray(user)
    }

    if (typeof user === 'object') {
      return this.parseFioObject(user)
    }

    if (typeof user === 'string') {
      return this.fioApplyDefaultValue({ surname: user })
    }

    return this.fioApplyDefaultValue()
  }

  /**
   * @private
   * @param user - {Array}
   */
  parseFioArray (user) {
    return this.fioApplyDefaultValue({
      name: user.at(0),
      surname: user.at(1),
      secondName: user.at(2),
    })
  }

  /**
   * @private
   * @param user
   */
  parseFioObject (user) {
    return this.fioApplyDefaultValue({
      name: user.name,
      surname: user.surname,
      secondName: user.second_name || user.secondName,
    })
  }

  /**
   * @private
   * @param user
   */
  fioApplyDefaultValue (user = {}) {
    return {
      name: user.name || '',
      surname: user.surname || '',
      secondName: user.secondName || '',
    }
  }
}

UserFormatter.namespace = 'user'
