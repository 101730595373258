<template>
  <div class="changelog-container__list">
    <div
      v-for="(item, index) in changelogEntries"
      :key="index"
      class="changelog-card"
      :class="{ 'changelog-card_active': item.id === changelogEntry.id}"
      @click="$emit('redirectToHash', item)"
    >
      <div class="changelog-card__title">
        <span
          v-if="item.types.length"
          class="changelog-badge"
          :style="{ backgroundColor: getBadgeBackgroundColorByName(item.types[0]) }"
        >{{ item.types[0] }}</span>
        <span class="changelog-card__title-text">{{ item.title }}</span>
      </div>

      <div class="changelog-card__body">
        {{ plaintext150(item.plaintextDetails) }}
      </div>
    </div>
  </div>
</template>

<script>
import { getBadgeBackgroundColorByName } from '@/vue_components/support/const/methods'

export default {
  name: 'ChangelogList',

  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },

    changelogEntries: {
      type: Array,
      default: () => [],
    },

    changelogEntry: {
      type: Object,
      default: () => ({}),
    },
  },

  data () {
    return {
      getBadgeBackgroundColorByName,
    }
  },

  methods: {
    plaintext150 (value) {
      const MAX_PLAIN_TEXT_LEN = 150

      if (value.length <= MAX_PLAIN_TEXT_LEN + 3) return value

      return value.slice(0, MAX_PLAIN_TEXT_LEN) + '...'
    },
  },
}
</script>
