<template>
  <panel-heading
    custom-class="hidden-print"
    class="company-router-container"
    :title="`${t('info_company')} - ${t('data_company')}`"
    icon="fad fa-user"
  >
    <template #buttons>
      <a
        onclick="window.print();return false;"
        class="btn btn-grey-dark btn-xs btn-with-icon hidden-print"
      >
        <span class="btn-with-icon_icon fad fa-fw fa-print" />
        {{ t('print') }}
      </a>
    </template>

    <div class="company-show">
      <panel-heading
        class="panel-inner left-container company-panel-print"
        :title="t('common_info')"
        icon="fad fa-info"
      >
        <flex-form
          :fields="flexForms.commonInfo"
        >
          <template #title-value>
            {{ GET_COMPANY_TITLE }}
          </template>

          <template #phones-value>
            <div class="company-show-phones">
              <phone
                v-if="GET_COMPANY_PHONE_1"
                :security-mask="false"
                :raw-phone="GET_COMPANY_PHONE_1"
                :surrogate="t('absence')"
              />
              <phone
                v-if="GET_COMPANY_PHONE_2"
                :security-mask="false"
                :raw-phone="GET_COMPANY_PHONE_2"
                :surrogate="t('absence')"
              />
            </div>
          </template>

          <template #types-value>
            {{ typesValue }}
          </template>

          <template #ownership-value>
            {{ GET_COMPANY_TYPE_OF_COMPANY }}
          </template>

          <template #inn-value>
            {{ GET_COMPANY_INDIVIDUAL_TAX_NUMBER }}
          </template>

          <template #kpp-value>
            {{ GET_COMPANY_KPP }}
          </template>

          <template #ogrn-value>
            {{ GET_COMPANY_OGRN }}
          </template>

          <template #okved-value>
            {{ GET_COMPANY_OKVED }}
          </template>

          <template #registration-date-value>
            <span v-if="GET_COMPANY_REGISTRATION_DATE">
              {{ $filters.date(GET_COMPANY_REGISTRATION_DATE) }}
            </span>
          </template>

          <template #additional-value>
            {{ GET_COMPANY_NOTE }}
          </template>

          <template #fact-address-value>
            {{ GET_COMPANY_FULL_FACT_ADDRESS }}
          </template>

          <template #formal-address-value>
            {{ GET_COMPANY_FULL_FORMAL_ADDRESS }}
          </template>

          <template #head-value>
            {{ headValue }}
          </template>

          <template #email-value>
            {{ GET_COMPANY_EMAIL }}
          </template>

          <template #web-value>
            {{ GET_COMPANY_WEB }}
          </template>
        </flex-form>

        <flex-form
          :fields="flexForms.tracker"
          class="hidden-print"
        >
          <template #created-by-value>
            {{ creator_user }}
            <span>{{ $filters.date(GET_COMPANY_CREATED_AT) }} </span>
          </template>

          <template
            v-if="GET_COMPANY_UPDATER_USER"
            #updated-by-value
          >
            {{ updater_user }}
            <span>{{ $filters.date(GET_COMPANY_UPDATED_AT) }} </span>
          </template>
          <template
            v-else
            #updated-by-value
          >
            {{ creator_user }}
            <span>{{ $filters.date(GET_COMPANY_CREATED_AT) }} </span>
          </template>
        </flex-form>
      </panel-heading>

      <div class="right-container">
        <panel-heading
          class="panel-inner company-panel-print"
          :title="t('finance_info')"
          icon="fad fa-money-bill"
        >
          <flex-form :fields="flexForms.finance">
            <template #personal-discount-value>
              <span>
                {{ GET_COMPANY_DISCOUNT }} %
              </span>
            </template>

            <template #finance-value>
              <span
                :class="companyFinance > 0 ? 'no_debt' : ''"
              >
                {{ $filters.currency(GET_COMPANY_FINANCE) }}
              </span>
            </template>

            <template #credit-debt-value>
              <span :class="companyDebt > 0 ? 'debt' : ''">
                {{ $filters.currency(GET_COMPANY_COMPANY_FINANCE_CREDIT_DEBT) }}
              </span>
            </template>
          </flex-form>
        </panel-heading>

        <panel-heading
          :title="t('statistic_info')"
          class="panel-inner"
          icon="fad fa-chart-bar"
        >
          <flex-form
            :fields="flexForms.statistic"
          >
            <template #entire-sum-value>
              {{ $filters.currency(GET_COMPANY_ALL_TIME_PAID_SUM) }}
            </template>
          </flex-form>
        </panel-heading>
      </div>

      <div class="bottom-container hidden-print flex gap-indent-small">
        <button
          type="button"
          class="btn btn-primary btn-sm btn-with-icon"
          :disabled="!canManage"
          @click="$router.push({ name: COMPANY_EDIT, params: { companyId: $route.params.companyId } })"
        >
          <i class="btn-with-icon_icon fad fa-edit" />
          <span class="btn-with-icon_text">
            {{ t('edit') }}
          </span>
        </button>

        <button
          class="btn btn-sm btn-danger btn-with-icon modal-save"
          :disabled="!canManage"
          @click="modal.deleteCompany.visibility = true"
        >
          <i class="btn-with-icon fad fa-times" />
          <span class="btn-with-icon_text">
            {{ t('delete') }}
          </span>
        </button>
      </div>

      <back-button pattern="companies" />

      <modal
        v-if="modal.deleteCompany.visibility"
        :modal-visibility="modal.deleteCompany.visibility"
        modal-class="delete-company"
        modal-size="md"
        @close="modal.deleteCompany.visibility = false"
      >
        <span slot="header">
          {{ t('confirm_delete') }}
        </span>

        <span slot="body">
          {{ t('confirm_delete_company') }}
        </span>

        <template slot="footer-right">
          <button
            class="btn btn-sm btn-danger btn-with-icon modal-save mr-5"
            @click="deleteCompany"
          >
            <i
              class="btn-with-icon_icon fad fa-exclamation-circle"
            />
            <span
              class="btn-with-icon_text"
            >
              {{ t('delete') }}
            </span>
          </button>

          <button
            class="btn btn-sm btn-primary modal-close mr-5"
            @click="modal.deleteCompany.visibility = false"
          >
            <i class="btn-with-icon_icon fad fa-times" />
            <span class="btn-with-icon_text">
              {{ t('cancel') }}
            </span>
          </button>
        </template>
      </modal>
    </div>
  </panel-heading>
</template>

<script>
import BackButton from '@/vue_components/common/buttons/back_button.vue'
import FlexForm from '@/vue_components/common/flex_form.vue'
import Modal from '@/vue_components/modal.vue'
import PanelHeading from '@/vue_components/common/panel_heading.vue'
import Phone from '@/vue_components/common/phone.vue'

import { mapActions, mapGetters } from 'vuex'
import { COMPANIES, COMPANY_EDIT } from '@/vue_components/router/modules/companies/route_names'
import { companiesEndpoint } from '@/api_entities/companies/companies_endpoint'

export default {
  name: 'CompanyShow',

  components: {
    BackButton,
    FlexForm,
    Modal,
    PanelHeading,
    Phone,
  },

  data () {
    return {
      modal: { deleteCompany: { visibility: false } },

      COMPANY_EDIT,
      COMPANIES,

      flexForms: {
        commonInfo: Object.freeze([
          { name: 'title', label: t('title') },
          { name: 'phones', label: t('phone') },
          { name: 'types', label: t('type') },
          { name: 'ownership', label: t('type_of_company') },
          { name: 'inn', label: t('inn') },
          { name: 'kpp', label: t('kpp') },
          { name: 'ogrn', label: t('ogrn') },
          { name: 'okved', label: t('okved') },
          { name: 'registration-date', label: t('registration_date') },
          { name: 'additional', label: t('additional') },
          { name: 'fact-address', label: t('address_fact') },
          { name: 'formal-address', label: t('address_formal') },
          { name: 'head', label: t('head') },
          { name: 'email', label: t('email') },
          { name: 'web', label: t('web') },
        ]),

        tracker: Object.freeze([
          { name: 'created-by', label: t('created_by') },
          { name: 'updated-by', label: t('updated_by') },
        ]),

        finance: Object.freeze([
          { name: 'finance', label: t('payer_finance') },
          { name: 'personal-discount', label: t('personal_discount') },
          { name: 'credit-debt', label: t('credit_debt') },
        ]),

        statistic: Object.freeze([
          { name: 'entire-sum', label: t('all_time_paid_sum') },
        ]),
      },
    }
  },

  computed: {
    ...mapGetters([
      'GET_COMPANY_TITLE',
      'GET_COMPANY_TYPE_OF_COMPANY',
      'GET_COMPANY_INDIVIDUAL_TAX_NUMBER',
      'GET_COMPANY_KPP',
      'GET_COMPANY_OGRN',
      'GET_COMPANY_OKVED',
      'GET_COMPANY_EMAIL',
      'GET_COMPANY_WEB',
      'GET_COMPANY_REGISTRATION_DATE',
      'GET_COMPANY_NOTE',
      'GET_COMPANY_FULL_FACT_ADDRESS',
      'GET_COMPANY_FULL_FORMAL_ADDRESS',
      'GET_COMPANY_HEAD_NAME',
      'GET_COMPANY_HEAD_SURNAME',
      'GET_COMPANY_HEAD_SECOND_NAME',
      'GET_COMPANY_DISCOUNT',
      'GET_COMPANY_FINANCE',
      'GET_COMPANY_COMPANY_FINANCE_CREDIT_DEBT',
      'GET_COMPANY_CREATOR_USER',
      'GET_COMPANY_UPDATER_USER',
      'GET_COMPANY_ALL_TIME_PAID_SUM',
      'GET_COMPANY_CREATED_AT',
      'GET_COMPANY_UPDATED_AT',
      'GET_COMPANY_PHONE_1',
      'GET_COMPANY_PHONE_2',
      'GET_COMPANY_COMPANY_TYPES',
    ]),

    typesValue () {
      return this.$filters.joinTruthy(this.GET_COMPANY_COMPANY_TYPES.map((type) => type.title))
    },

    headValue () {
      return Utils.joinTruthy([this.GET_COMPANY_HEAD_SURNAME, this.GET_COMPANY_HEAD_NAME, this.GET_COMPANY_HEAD_SECOND_NAME])
    },

    companyDebt () {
      return parseFloat(this.GET_COMPANY_COMPANY_FINANCE_CREDIT_DEBT)
    },

    companyFinance () {
      return parseFloat(this.GET_COMPANY_FINANCE)
    },

    creator_user () {
      return this.GET_COMPANY_CREATOR_USER
        ? this.GET_COMPANY_CREATOR_USER.surname + ' ' +
          this.GET_COMPANY_CREATOR_USER.name.charAt(0) + '.' +
          this.GET_COMPANY_CREATOR_USER.second_name.charAt(0) + '.'
        : ''
    },
    updater_user () {
      return this.GET_COMPANY_UPDATER_USER
        ? this.GET_COMPANY_UPDATER_USER.surname + ' ' +
        this.GET_COMPANY_UPDATER_USER.name.charAt(0) + '.' +
        this.GET_COMPANY_UPDATER_USER.second_name.charAt(0)
        : ''
    },

    canManage () {
      return this.$security.canManageCompany
    },
  },

  created () {
    this.LOAD_COMPANY(this.$route.params.companyId)
  },

  methods: {
    ...mapActions([
      'LOAD_COMPANY',
    ]),

    deleteCompany () {
      this.modal.deleteCompany.visibility = false
      companiesEndpoint.destroy(this.$route.params.companyId)
        .then((res) => {
          if (res.deleted_by_id) {
            this.$router.push({ name: COMPANIES })
            Notificator.success(t('record_successfully_deleted'))

            return
          }
          Notificator.error(t('abstract_error_message'))
        })
        .catch(() => {
          Notificator.error(t('abstract_error_message'))
        })
    },
  },
}
</script>
