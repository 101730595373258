<template>
  <transition :name="modalTransition">
    <div
      v-show="modalVisibility"
      ref="modal"
      :style="modalVisibility ? { display: 'block', zIndex } : { zIndex }"
      class="modal in vue-modal-in"
      @keydown.esc="$emit('close')"
    >
      <div
        class="modal-dialog"
        :class="[
          `modal-${modalSize}`,
          modalClass,
          fullscreenModalDialogClass,
        ]"
      >
        <div
          class="modal-content"
          :class="{ 'vh-100': fullHeight }"
        >
          <div class="modal-header">
            <div class="modal-title">
              <slot name="header" />
            </div>
            <div class="modal-buttons">
              <span
                v-if="fullscreenButtonEnabled"
                :class="`header-button modal-header-fullscreen ${fullscreenBtnIconClass}`"
                @click="fullscreenButtonClick"
              />
              <span
                v-if="closeButtonEnabled"
                class="header-button modal-header-close fad fa-times"
                @click="$emit('close')"
              />
            </div>
          </div>
          <div class="modal-body">
            <slot name="body" />
          </div>
          <div
            v-if="!removeFooter"
            class="modal-footer"
          >
            <div class="modal-footer-left">
              <slot name="footer-left" />
            </div>
            <div class="modal-footer-right">
              <slot name="footer-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
const MODAL_HEADER_BUTTONS = Object.freeze({
  CLOSE: 'close',
  FULLSCREEN: 'fullscreen',
})

export default {
  name: 'Modal',
  props: {
    modalVisibility: {
      type: Boolean,
      default: false,
    },
    modalClass: {
      type: String,
      default: '',
    },
    modalSize: {
      type: String,
      default: 'lg',
    },
    modalTransition: {
      type: String,
      default: 'vue-modal-default-transition',
    },
    modalHeaderButtons: {
      type: Array,
      default: () => [MODAL_HEADER_BUTTONS.CLOSE],
    },
    inBody: {
      type: Boolean,
    },
    zIndex: {
      type: Number,
      default: 1050,
    },
    removeFooter: Boolean,
    fullHeight: Boolean,
  },

  data () {
    return {
      fullscreenModal: false,
    }
  },

  computed: {
    closeButtonEnabled () {
      return this.modalHeaderButtons.includes(MODAL_HEADER_BUTTONS.CLOSE)
    },

    fullscreenButtonEnabled () {
      return this.modalHeaderButtons.includes(MODAL_HEADER_BUTTONS.FULLSCREEN)
    },

    fullscreenBtnIconClass () {
      if (this.fullscreenModal) {
        return 'fad fa-compress'
      }

      return 'fad fa-expand'
    },

    fullscreenModalDialogClass () {
      if (this.fullscreenModal) {
        return 'modal-dialog-fullscreen'
      }

      return ''
    },
  },
  mounted () {
    if (this.inBody) {
      document.body.appendChild(this.$refs.modal)
    }
  },
  methods: {
    fullscreenButtonClick () {
      if (this.fullscreenModal) {
        return this.collapseFromFullscreen()
      }

      return this.resizeToFullscreen()
    },

    collapseFromFullscreen () {
      this.fullscreenModal = false
      this.$emit('resize')
    },

    resizeToFullscreen () {
      this.fullscreenModal = true
      this.$emit('true')
    },
  },
}
</script>
