import formatter from '@/lib/formatters/formatter'
import { entryTypesIconMap } from '@/modules/utils/medods_ui/entryTypesKindsIcons'

/**
 * @type {TVueFilters}
 */
export const vueFilters = {
  price (value) {
    return formatter.formatValue(Utils.toMoney(value), ['price'])
  },

  currency (value) {
    return formatter.formatValue(Utils.toMoney(value), ['number:currencyWithSign'])
  },

  date (value) {
    return formatter.formatValue(value, ['date:localDate'])
  },

  clinicDate (value) {
    return formatter.formatValue(value, ['date:clinicDate'])
  },

  dateTime (value) {
    return moment(value).format(Utils.momentDateTimeFormat)
  },

  time (value, format) {
    if (!moment(value).isValid()) { return value }

    return moment(value).format(format || Utils.elTimeFormat)
  },

  route (value, route) {
    if (!window?.Routes[route]) { return '' }

    return window?.Routes[route](value)
  },

  address (value) {
    return formatter.formatValue(value, ['client:address'])
  },

  fullName (value) {
    return formatter.formatValue(value, ['client:fullName'])
  },

  shortName (value) {
    return formatter.formatValue(value, ['user:shortName'])
  },

  rateType (value) {
    if (!value) { return '' }

    return formatter.formatValue(value, ['number:rateType'])
  },

  age (birthdate) {
    if (!birthdate) return ''

    const now = moment()
    const yearAgo = moment().subtract(1, 'year')
    const birthday = moment(birthdate)

    if (birthday.isBefore(yearAgo)) {
      let age = now.year() - birthday.year()
      birthday.year(now.year())
      if (birthday.isAfter(now)) {
        age--
      }

      return `${age} ${formatter.formatValue(age, ['date:agePostfix'])}`
    }

    return t('reports.less_than_year')
  },

  doctors (doctors) {
    return doctors.length
      ? doctors.map((doctor) => formatter.formatValue(doctor, ['user:name']))
        .join(', ')
      : '-'
  },

  entryTypeIcon (kind) {
    return entryTypesIconMap[kind]
  },

  clientPhone (client) {
    return client.formatted_phone ||
      client.masked_phone ||
      client.formattedPhone ||
      client.maskedPhone || ''
  },

  phoneMask (phone, defaultValue = t('absence')) {
    return phone ? `+ ${Services.phoneMask.setMask(phone)}` : defaultValue
  },

  replaceEmpty (value) {
    return formatter.formatValue(value, [['replaceEmpty', '-']])
  },

  replaceNull (value) {
    return formatter.formatValue(value, [['replaceNull', '-']])
  },

  joinTruthy (items, separator = ', ') {
    return Utils.joinTruthy(items, separator)
  },
}
