<template>
  <div class="payment-cash-out-form">
    <epic-spinner
      :visibility="loading"
    />

    <flex-form :fields="flexForms.cashOut">
      <template #customer-value>
        {{ customer }}
      </template>

      <template #payment-kind-value>
        {{ T._payment_kind.cash_out }}
      </template>

      <template #by-balance-value>
        <currency-input
          :value="accessBalance"
          disabled
          icon="fad fa-user-plus"
        />
      </template>

      <template #refund-method-value>
        <validation-wrapper
          :errors="validationMessages.paymentSpec"
        >
          <items-list
            id-item-field="key"
            displayed-item-field="title"
            :items="paymentSpecies"
            :min-selected-items="1"
            :multi-select="false"
            :selected-items-ids="paymentSpec"
            :disabled="loading"
            :class="['company-types-list', {loading: loading}]"
            @change="update('paymentSpec', $event)"
          />
        </validation-wrapper>
      </template>

      <template #sum-value>
        <validation-wrapper
          :errors="validationMessages.summaryInputUser"
        >
          <currency-input
            :value="summaryInputUser"
            icon="fad fa-money-bill"
            icon-right="fad fa-reply reply-button"
            @right-btn-handler="handleReplySum('summaryInputUser', accessBalance)"
            @input="update('summaryInputUser', $event)"
          />
        </validation-wrapper>
      </template>

      <template #comment-value>
        <el-input
          type="textarea"
          :value="comment"
          :rows="2"
          :placeholder="T.comment"
          @input="update('comment', $event)"
        />
      </template>
    </flex-form>
  </div>
</template>

<script>
import CurrencyInput from '@/vue_components/common/currency_input.vue'
import FlexForm from '@/vue_components/common/flex_form.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import ItemsList from '@/vue_components/items_list.vue'
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'

import { PAYMENT_SPECIES } from './consts.js'

export default {
  name: 'PaymentsCashOutForm',
  components: {
    CurrencyInput,
    FlexForm,
    EpicSpinner,
    ItemsList,
    ValidationWrapper,
  },

  props: {
    customer: {
      type: String,
      required: true,
    },
    paymentSpec: {
      type: Array,
      required: true,
    },
    summaryInputUser: {
      type: Number,
      required: true,
    },
    accessBalance: {
      type: Number,
      required: true,
    },
    validationMessages: {
      type: Object,
      default () {},
    },
    comment: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    handleReplySum: {
      type: Function,
      default () {},
    },
  },

  data () {
    return {
      paymentSpecies: PAYMENT_SPECIES.filter((spec) => spec.key !== 'by_balance'),
      flexForms: {
        cashOut: Object.freeze([
          { name: 'customer', label: t('cuctomer_signature') },
          { name: 'payment-kind', label: t('payment_kind') },
          { name: 'by-balance', label: 'Доступно' },
          { name: 'refund-method', label: t('refund_method') },
          { name: 'sum', label: t('sum') },
          { name: 'comment', label: t('comment') },
        ]),
      },
    }
  },

  methods: {
    update (prop, value) {
      this.$emit(`update:${prop}`, value)
    },
  },
}
</script>
