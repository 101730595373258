import { WABA_TEMPLATE_TEXT_SECTION as WABA_TEMPLATE_BODY_COMPONENT_TYPE } from './consts'
import { WHATSAPP_VARIABLE_CSS_CLASS } from '@/vue_present/VariablesEditorWhatsapp/consts/consts'
import TemplateConverter from 'src/vue_present/VariablesEditor/consts/templateConverter'
import {
  smsTemplateVariablesLabels as variablesLabelsMap,
  smsTemplateVariablesValues as variablesValuesMap,
} from '@/vue_components/app_configuration/messages/_messages_configurations_base/consts/variables'

const replaceVariablesTagsByValues = (text, variablesValues) => {
  if (!variablesValues[0]) { return text }

  const variableTagRegexp = /{{\s*(\d+)\s*}}/g

  return text.replace(variableTagRegexp, (_, capture) =>
      `<span class="${WHATSAPP_VARIABLE_CSS_CLASS}">{{${variablesValues[parseInt(capture) - 1]}}}</span>`)
}

const replaceVariablesValuesByLabels = (text) => {
  return new TemplateConverter(text, variablesLabelsMap, variablesValuesMap).toClient()
}

const replaceVariablesTagsByLabels = (templateText, templateVariablesValues) => {
  const textWithVariablesValues = replaceVariablesTagsByValues(templateText, templateVariablesValues)

  return replaceVariablesValuesByLabels(textWithVariablesValues)
}

const findWabaTemplate = (templates, templateGuid) => {
  return templates.find((t) => t.templateGuid === templateGuid)
}

const findWabaTemplateBodyComponent = (components) => {
  return components.find((c) => c.type === WABA_TEMPLATE_BODY_COMPONENT_TYPE)
}

export const replaceVariablesLabelsByValues = (text) => {
  return new TemplateConverter(text, variablesLabelsMap, variablesValuesMap).toServer()
}

export const extractWabaTemplateText = (template) => {
  return findWabaTemplateBodyComponent(template.components).text
}

export const findAndExtractWabaTemplateText = (wabaTemplates, templateGuid) => {
  const neededWabaTemplate = findWabaTemplate(wabaTemplates, templateGuid)

  return extractWabaTemplateText(neededWabaTemplate)
}

export const findAndExtractWabaTemplateTextWithVariablesLabels = ({
  wabaTemplates,
  templateGuid,
  templateVariablesValues,
}) => {
  const templateText = findAndExtractWabaTemplateText(wabaTemplates, templateGuid)

  return replaceVariablesTagsByLabels(templateText, templateVariablesValues)
}
