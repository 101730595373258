import { NODE_ACTIONS } from '@/vue_present/Reuse/CatalogTree/const/const'

export const DISEASES_TREE_NODE_ACTIONS_DELEGATES = [
  NODE_ACTIONS.ADD_ITEM,
  NODE_ACTIONS.EDIT,
]

export const DISEASES_TREE_NODE_ACTIONS_OMITS = {
  LEAF: [],
  CATEGORY: [
    NODE_ACTIONS.ADD_FOLDER,
    NODE_ACTIONS.EDIT,
    NODE_ACTIONS.DELETE,
  ],
}

export const DISEASES_TREE_EDITABLE_NODE_LEVEL = 2

export const MAX_DISEASE_TITLE_LENGTH = 255
