<template>
  <div class="clinic-logotypes">
    <div class="max-file-size">
      {{ maxFileSizeMessage }}
    </div>
    <div
      ref="clinicLogotypesNew"
      class="logo-container"
    >
      <div class="title">
        {{ t('activerecord.attributes.clinic.logo') }}
      </div>
      <logotype
        v-if="logoSource"
        :logo-source="logoSource"
        :epic-spinner="showEpicSpinnerForLogotype"
        @input="uploadLogo"
        @delete="deleteLogo"
      />
      <logotype-not-found
        v-else
        :epic-spinner="showEpicSpinnerForLogotype"
        @input="uploadLogo"
      />
    </div>
    <div class="logo-container">
      <div class="title">
        {{ t('activerecord.attributes.clinic.widget_clinic_logo') }}
      </div>
      <element-stub-wrapper
        :is-visible="isShowStubModal"
        :requirements="[
          { name: MODULES.CLIENT_WIDGET, isEnabled: GET_APP_CONF_CLIENT_WIDGET_MODULE_ENABLED },
          { name: OPTIONS.CLIENT_WIDGET_AVAILABLE, isEnabled: $security.canViewClientWidgetConfiguration },
        ]"
        :is-available-settings="false"
        @changeVisibility="$updateSync('isShowStubModal', $event)"
        @click="$emit('askShowStubModal')"
      >
        <widget-logotype
          :logo-is="widgetLogoIs"
          :logo-source="widgetLogoSource"
          :epic-spinner="showEpicSpinnerForWidgetLogotype"
          @cropped="uploadWidgetLogo"
          @delete="deleteWidgetLogo"
        />
      </element-stub-wrapper>
    </div>
    <input
      ref="logo"
      type="hidden"
      name="clinic[logo]"
    >
    <input
      ref="widgetLogo"
      type="hidden"
      name="clinic[widget_logo]"
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logotype from './logotype.vue'
import LogotypeNotFound from './logotype_not_found.vue'
import WidgetLogotype from './widget_logotype.vue'
import ElementStubWrapper from '@/vue_components/stubs/element_stub_wrapper'
import { MODULES, OPTIONS } from '@/specific/stubs/_helpers/constants'

const widgetRoute = '/default_avatars/widget_clinic_logo.svg'

export default {
  name: 'ClinicLogotypesNew',
  components: {
    Logotype,
    WidgetLogotype,
    LogotypeNotFound,
    ElementStubWrapper,
  },
  props: {
    logo: {
      type: String,
      default: null,
    },
    widgetLogo: {
      type: String,
      default: null,
    },
    isSaveNewClinic: Boolean,
    isShowStubModal: Boolean,
  },
  data () {
    return {
      showEpicSpinnerForLogotype: false,
      showEpicSpinnerForWidgetLogotype: false,
      widgetLogoSource: widgetRoute,
      logoSource: null,
      logoExist: false,
      widgetLogoIs: false,
      maxFileSize: gon.application.files.max_size,
      MODULES,
      OPTIONS,
    }
  },
  computed: {
    ...mapGetters([
      'GET_APP_CONF_CLIENT_WIDGET_MODULE_ENABLED',
    ]),
    maxFileSizeToMb () {
      return Math.floor(this.maxFileSize / (1024 * 1024))
    },
    maxFileSizeMessage () {
      return `${t('max_file_size')} ${this.maxFileSizeToMb}  ${t('mbyte')}`
    },
  },
  watch: {
    isSaveNewClinic () {
      this.widgetLogoIs = false
      this.widgetLogoSource = widgetRoute
      this.$refs.widgetLogo.value = null

      this.logoSource = null
      this.$refs.logo.value = null
    },
  },
  methods: {
    uploadWidgetLogo (event) {
      const { cropped } = event
      const dataURL = cropped.toDataURL()
      const base64 = dataURL.split(',')[1]
      const fileSize = atob(base64).length

      if (fileSize > this.maxFileSize) {
        Notificator.warning(Utils.maxFileSizeMessage())

        return
      }

      this.showEpicSpinnerForWidgetLogotype = true
      Notificator.success(t('successful_logo_uploading'))

      this.showEpicSpinnerForWidgetLogotype = false
      this.widgetLogoIs = true
      this.widgetLogoSource = dataURL
      this.$refs.widgetLogo.value = base64

      this.$updateSync('widgetLogo', base64)
    },
    uploadLogo (event) {
      const file = event.target.files[0]
      const fileReader = new FileReader()

      if (file.size > this.maxFileSize) {
        Notificator.warning(Utils.maxFileSizeMessage())

        return
      }

      this.showEpicSpinnerForLogotype = true

      fileReader.onload = () => {
        const dataURL = fileReader.result
        const base64 = dataURL.split(',')[1]

        Notificator.success(t('successful_logo_uploading'))
        this.showEpicSpinnerForLogotype = false
        this.$refs.logo.value = base64
        this.logoSource = dataURL

        this.$updateSync('logo', base64)
      }

      fileReader.readAsDataURL(file)
    },
    deleteWidgetLogo () {
      this.showEpicSpinnerForWidgetLogotype = true

      Notificator.success(t('successful_logo_removal'))

      this.showEpicSpinnerForWidgetLogotype = false
      this.widgetLogoIs = false
      this.widgetLogoSource = widgetRoute
      this.$refs.widgetLogo.value = null
    },
    deleteLogo () {
      this.showEpicSpinnerForLogotype = true

      Notificator.success(t('successful_logo_removal'))

      this.showEpicSpinnerForLogotype = false
      this.logoSource = null
      this.$refs.logo.value = null
    },
  },
}
</script>
