<template>
  <el-popover
    width="50"
    trigger="click"
  >
    <div id="icons-right">
      <i
        id="navbar-info"
        class="fad fa-fw fa-info-circle tooltip-bottom popover-icon"
        :title="T.info"
      />
      <i
        v-if="current_user"
        id="navbar-notifications"
        class="fad fa-fw fa-bell tooltip-bottom popover-icon"
        :title="T.notifications.header"
      >
        <span class="notification-badge" />
      </i>
      <softphone-handler />
      <i
        v-if="current_user"
        id="navbar-logout"
        class="fad fa-fw fa-sign-out-alt tooltip-bottom popover-icon"
        :title="T.logout"
        @click="logout"
      />
    </div>
  </el-popover>
</template>

<script>
import SoftphoneHandler from '@/vue_components/communications/telephony/softphone/softphone_handler.vue'

export default {
  name: 'NavbarRightIcons',
  components: {
    SoftphoneHandler,
  },
  data () {
    return {
      current_user: gon.application.current_user,
    }
  },
  methods: {
    logout () {
      $.ajax({
        url: Routes.destroy_user_session_path(),
        method: 'DELETE',
        success () {
          Turbolinks.visit('/')
        },
      })
    },
  },
}
</script>
