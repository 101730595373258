<template>
  <panel-heading
    :title="`${t('info_company')} - ${t('documents')}`"
    icon="fad fa-shopping-cart"
    class="company-router-container"
  >
    <div class="company-documents">
      <si>
        <si-control
          class="d-flex"
          :disabled="tableSpinner"
          @reset="filters.reset()"
        >
          <div class="flex flex-grow-1 w-100">
            <button
              v-tooltip="t('add_document')"
              class="btn btn-success btn-sm no-margin-top mr-15"
              type="button"
              @click="showAddDocumentModal"
            >
              <i class="fad fa-copy btn-icon-left" />
              <i class="fad fa-plus btn-icon-left" />
            </button>

            <search-input
              :value.sync="filters.documentId"
              :placeholder="t('document_num')"
              :debounce="400"
              class="search"
            />
          </div>

          <date-range-picker-btn
            v-model="filters.dateRange"
            class="si-control-item"
            :disabled="tableSpinner"
            :fallback-value="[]"
            unpressed
          />

          <date-range-picker
            v-model="filters.dateRange"
            class="si-control-item"
            :disabled="tableSpinner"
            :fallback-value="[]"
            :format="GET_LOCALIZATION_DATEPICKER_FORMAT"
          />
          <template #refresh>
            <span />
          </template>
        </si-control>

        <si-table
          :spinner="tableSpinner"
          :not-found="!documents.list.length"
          :error="documents.hasError"
        >
          <template #header>
            <si-field>
              <div class="number">
                {{ t('common.number') }}
              </div>

              <div class="date">
                {{ t('common.date') }}
              </div>

              <div class="title">
                {{ t('title') }}
              </div>
            </si-field>
          </template>

          <template #body>
            <si-field
              v-for="doc in documents.list"
              :key="doc.id"
            >
              <div class="number">
                <a
                  :href="$routes.document_path(doc.id)"
                  target="_blank"
                >
                  {{ doc.number }}
                </a>
              </div>

              <div class="date">
                {{ $filters.date(doc.created_at) }}
              </div>

              <div class="title">
                {{ doc.title }}
              </div>
            </si-field>
          </template>
          <template #not-found-message>
            <si-not-found
              :control-constraints="shouldControlConstraints"
              @remove-constraints="removeConstraints"
            />
          </template>
        </si-table>

        <si-footer>
          <pagination
            layout="prev, pager, next"
            :page-size="pagination.pageSize"
            :page-count="pagination.pageCount"
            :current-page.sync="pagination.currentPage"
          />
        </si-footer>
      </si>
    </div>
  </panel-heading>
</template>

<script>
import DateRangePicker from '@/vue_components/common/select/date_range_picker.vue'
import DateRangePickerBtn from '@/vue_components/common/select/date_range_picker_btn.vue'
import Pagination from '@/vue_components/common/pagination.vue'
import PanelHeading from '@/vue_components/common/panel_heading.vue'
import SearchInput from '@/vue_components/common/search_input.vue'
import Si from '@/vue_components/sort_items/si.vue'
import SiControl from '@/vue_components/sort_items/si_control.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'

import { mapGetters } from 'vuex'
import { PAGINATION_PAGE_SIZE } from '@/vue_components/sort_items/consts.js'
import { CompanyDocumentsFilters } from './consts.js'
import { generateUID } from '@/vue_components/companies/helpers/storage'
import { companiesEndpoint } from '@/api_entities/companies/companies_endpoint'
import SiNotFound from '@/vue_components/sort_items/si_not_found'

export default {
  name: 'CompanyDocuments',

  components: {
    SiNotFound,
    DateRangePicker,
    DateRangePickerBtn,
    Pagination,
    PanelHeading,
    SearchInput,
    Si,
    SiControl,
    SiField,
    SiFooter,
    SiTable,
  },

  data () {
    const userCompanyKey = generateUID(
      this.$store.getters.GET_APP_CONF_CURRENT_USER_ID,
      this.$route.params.companyId
    )

    return {
      filters: new CompanyDocumentsFilters(userCompanyKey),
      documents: {
        list: [],
        hasError: false,
        loading: false,
      },
      pagination: {
        pageSize: PAGINATION_PAGE_SIZE,
        pageCount: 0,
        currentPage: 1,
      },
    }
  },

  computed: {
    ...mapGetters([
      'GET_LOCALIZATION_DATEPICKER_FORMAT',
      'GET_LOCALIZATION_CURRENCY_PARAMS',
      'GET_APP_CONF_CURRENT_USER_ID',
    ]),

    shouldControlConstraints () {
      return this.filters.dateRange && !!this.filters.documentId
    },

    documentsFilters () {
      return this.filters.get()
    },

    reqDocumentsParams () {
      return {
        ...this.documentsFilters,
        company_id: this.$route.params.companyId,
        per_page: this.pagination.pageSize,
        page: this.pagination.currentPage,
      }
    },

    tableSpinner () {
      return this.GET_COMPANY_LOADING || this.documents.loading
    },
  },

  watch: {
    reqDocumentsParams () {
      this.fetchDocuments()
    },

    documentsFilters () {
      this.pagination.currentPage = 1
    },
  },

  mounted () {
    this.fetchDocuments()
  },

  methods: {
    removeConstraints () {
      this.filters.dateRange = null
    },

    showAddDocumentModal () {
      try {
        const documents = gon.specific.documents
        DocumentForm.init({
          documents,
          company_id: this.$route.params.companyId,
          documentDatesModalSelector: '#document_dates_modal',
          itemEditable: true,
          inModal: true,
          groupEditOlnly: true,
          source: 'show',
          redirectDestination: 'companies_entry_point', //method in controller for redirect after action
        })

        DocumentForm.show()
      } catch (error) {
        Utils.reportError('company_documents:showAddDocumentModal')(error)
      }
    },

    fetchDocuments () {
      this.documents.loading = true
      this.documents.hasError = false

      companiesEndpoint.getDocuments(this.$route.params.companyId, this.reqDocumentsParams)
        .then((response) => {
          this.documents.list = response.documents
          this.pagination.pageCount = response.total_pages
        })
        .catch((error) => {
          this.documents.hasError = true
          Utils.reportError('company_documents:fetchDocuments')(error)
        })
        .finally(() => {
          this.documents.loading = false
        })
    },
  },
}

</script>
