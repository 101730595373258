<template>
  <span
    class="min-string-length-hint"
  >
    <slot>
      {{ T.please_enter_least }} {{ calcRest }} {{ declOfNum(calcRest) }}
    </slot>
  </span>
</template>

<script>
import NumberDeclension from '@/lib/number_declension/number_declension.js'

export default {
  name: 'MinStringLengthHint',

  props: {
    value: {
      type: String,
      default: '',
    },

    rest: {
      type: Number,
      default: 3,
    },

    minLength: {
      type: Number,
      default: 3,
    },
  },

  data () {
    return {
      declensions: {
        one: t('symbol_1'),
        few: t('symbols'),
        many: t('symbol_2'),
      },
    }
  },

  computed: {
    calcRest () {
      let rest = 0

      if (this.value.length < this.minLength) {
        rest = this.minLength - this.value.length
      }

      return rest
    },
  },

  watch: {
    calcRest: {
      immediate: true,
      handler (newRest) {
        this.$emit('update:rest', newRest)
        this.$emit('rest', newRest)
      },
    },
  },

  methods: {
    declOfNum (n) {
      const dec = new NumberDeclension(this.declensions)

      return dec.declaration(n)
    },
  },
}
</script>
