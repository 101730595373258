<template>
  <div class="flex flex-column">
    <span class="mb-indent-small">{{ title }}</span>

    <document-square-field-labeled
      class="mb-indent-mid justify-content-between"
    >
      <template #label>
        <span>{{ t('surname') }}</span>
        <span>{{ t('name') }}</span>
        <span>{{ t('second_name') }}</span>
        <span>{{ `${t('inn')}**` }}</span>
      </template>

      <template #field>
        <!-- Фамилия -->
        <document-square-field
          :value="client.surname"
          :line-size="36"
        />

        <!-- Имя -->
        <document-square-field
          :value="client.name"
          :line-size="36"
        />

        <!-- Отчество -->
        <document-square-field
          :value="client.secondName"
          :line-size="36"
        />

        <!-- ИНН -->
        <div class="flex gap-indent-2">
          <document-square-field
            :value="client.inn"
            :line-size="12"
          />

          <!-- Дата рождения -->
          <document-square-field-labeled>
            <template #label>
              <span style="margin-left: 32px">{{ t('birthdate') }}</span>
            </template>

            <template #field>
              <document-square-field-date :value="client.birthdate" />
            </template>
          </document-square-field-labeled>
        </div>
      </template>
    </document-square-field-labeled>

    <div class="flex flex-column">
      <span class="mb-indent-small">{{ t$('documentTitle') }}</span>

      <div class="flex mb-indent-mid">
        <document-square-field-labeled style="gap: 22px">
          <template #label>
            <span>{{ t$('fnsDocumentCodeTitle') }}</span>
            <span>{{ t('issue_date') }}</span>
          </template>

          <template #field>
            <div
              style="gap: 103px"
              class="flex"
            >
              <!-- Код вида документа -->
              <document-square-field
                :value="client.fnsIdCard?.id"
                :line-size="2"
              />

              <!-- Серия и номер -->
              <document-square-field-labeled>
                <template #label>
                  <span>{{ t('seriesAndNumber') }}</span>
                </template>

                <template #field>
                  <document-square-field
                    :value="seriesAndNumber"
                    :line-size="20"
                  />
                </template>
              </document-square-field-labeled>
            </div>

            <!-- Дата выдачи -->
            <document-square-field-date :value="client.idCard.issueDate" />
          </template>
        </document-square-field-labeled>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TaxCertificate2024,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import DocumentSquareFieldLabeled
  from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareFieldLabeled/DocumentSquareFieldLabeled.vue'
import DocumentSquareField from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareField.vue'
import DocumentSquareFieldDate
  from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareFieldDate/DocumentSquareFieldDate.vue'

export default {
  name: 'TaxCertificate2024PrintablePersonalInformation',

  components: {
    DocumentSquareFieldDate,
    DocumentSquareField,
    DocumentSquareFieldLabeled,
  },

  mixins: [i18nScopeMixin],

  props: {
    certificate: TaxCertificate2024,
    isTaxpayer: Boolean,
  },

  computed: {
    clientProp () {
      return this.isTaxpayer
        ? 'taxpayer'
        : 'client'
    },

    /** @return {TaxCertificate2024Client} */
    client () {
      return this.certificate[this.clientProp]
    },

    title () {
      return this.t$(this.isTaxpayer
        ? 'taxpayerTitle'
        : 'clientTitle'
      )
    },

    seriesAndNumber () {
      return [
        this.client.idCard?.series ?? '',
        this.client.idCard?.number ?? '',
      ].join(' ')
    },
  },

  created () {
    this.setI18nScope('fixedDocument.taxCertificate2024.print')
  },
}
</script>
