export const dateNotInFutureValidator = (value: unknown) => {
  const errors = Utils.getValidationErrors(value)

  if (errors.length) { return errors }

  const now = Utils.getTimezonedDateTime()
  const date = Utils.getTimezonedMoment(value)

  if (!date.isBefore(now)) {
    return [t('errors.dateCannotBeInFuture')]
  }

  return []
}
