<template>
  <div class="widget-logotype">
    <upload-control>
      <epic-spinner
        :visibility="epicSpinner"
      />
      <img
        :src="logoSource"
        class="img-logo"
      >
      <template
        v-if="$security.canManageClinic"
        #control
      >
        <upload-button>
          <slot name="input">
            <input
              ref="logoInput"
              accept="image/jpeg, image/png, image/gif"
              type="file"
              @input="handleInput"
            >
          </slot>
        </upload-button>
        <delete-button
          v-if="logoIs"
          @click="showModal"
        />
      </template>
    </upload-control>

    <modal-image-cropper
      :preview="true"
      :targets="IMAGE_CROPPER_EVENTS"
      :visible="showModalImageCropper"
      @close="handleClosure"
    >
      <span slot="header">
        {{ t('logo_editing') }}
      </span>
      <span slot="footer-right">
        <button
          class="btn btn-success btn-with-icon moda"
          type="button"
          @click="cropLogo"
        >
          <div class="btn-with-icon_icon fad fa-cut" />
          <div class="btn-with-icon_text"> {{ t('save_changes') }} </div>
        </button>
      </span>
    </modal-image-cropper>

    <confirmation-modal
      :header-message="t('warning')"
      :message="t('clinic_logo_removal_warning')"
      :modal-visibility="showConfirmingModal"
      modal-size="md"
      @yes="showConfirmingModal = false; $emit('delete')"
      @no="showConfirmingModal = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import UploadButton from '@/vue_components/common/upload_button.vue'
import DeleteButton from '@/vue_components/common/delete_button.vue'
import UploadControl from './upload_control.vue'
import ConfirmationModal from '@/vue_components/confirmation_modal.vue'
import ModalImageCropper from '@/vue_components/modal_image_cropper.vue'

const IMAGE_CROPPER_EVENTS =
  Object.freeze({
    SET_IMAGE: 'IMAGE_CROPPER.SET_WIDGET_LOGO',
    CROP_IMAGE: 'IMAGE_CROPPER.CROR_WIDGET_LOGO',
    CROPPED_IMAGE: 'IMAGE_CROPPER.CROPPED_WIDGET_LOGO',
  })

export default {
  name: 'WidgetLogotype',
  components: {
    UploadButton,
    UploadControl,
    DeleteButton,
    EpicSpinner,
    ConfirmationModal,
    ModalImageCropper,
  },
  props: {
    logoSource: {
      type: String,
      default: null,
    },
    logoIs: Boolean,
    epicSpinner: Boolean,
  },
  data () {
    return {
      showConfirmingModal: false,
      showModalImageCropper: false,
      file: null,
      IMAGE_CROPPER_EVENTS,
    }
  },
  computed: {
    ...mapGetters([
      'GET_APP_CONF_CLIENT_WIDGET_MODULE_ENABLED',
    ]),
  },
  mounted () {
    this.$pubSub.subscribe(this.IMAGE_CROPPER_EVENTS.CROPPED_IMAGE, (canvas) => {
      this.$emit('cropped', {
        file: this.file,
        cropped: canvas,
      })
    })
  },
  methods: {
    cropLogo () {
      this.$pubSub.emit(this.IMAGE_CROPPER_EVENTS.CROP_IMAGE)
      this.showModalImageCropper = false
    },
    handleInput (event) {
      this.file = event.target.files[0]
      this.showModalImageCropper = true

      this.$nextTick(() => {
        this.$pubSub.emit(this.IMAGE_CROPPER_EVENTS.SET_IMAGE, this.file)
        this.$refs.logoInput.value = ''
      })
    },
    handleClosure () {
      this.showModalImageCropper = false
      this.file = null
    },
    showModal () {
      if (!this.$security.canViewClientWidgetConfiguration || !this.GET_APP_CONF_CLIENT_WIDGET_MODULE_ENABLED) return

      this.showConfirmingModal = true
    },
  },
}
</script>
