import Vue from 'vue/dist/vue.esm'
import { createStore } from '@/vue_components/store/store'
import { createRouter } from '@/vue_components/router/router'

/**
 * @typedef {{
 *   el: String,
 *   render: Function,
 *   name?: String,
 *   router?: Object,
 *   store?: Object
 *   useChildren?: boolean
 * }} VueConfigCustom
 */

/**
 * @param {VueConfigCustom} vueConfig
 */
const createVueAppByConfig = (vueConfig) => {
  const instance = new Vue({
    store: createStore(),
    router: createRouter(),
    ...vueConfig,
  })

  return vueConfig.useChildren
    ? instance.$children.at(0)
    : instance
}

/**
 * @param el
 * @return {boolean}
 */
const isElementExists = (el) => {
  if (!el) { return false }

  return Boolean(document.querySelector(el))
}

/**
 * Создаст Vue app на основе конфига
 * @param {Object} vueConfig
 * @returns {*}
 */
const initVueInBody = (vueConfig) => {
  const { el } = vueConfig

  if (!el) {
    throw new Error('missing el')
  }

  if ($(el).length > 0) {
    throw new Error('el already exists')
  }

  $('body').append($(`<div id="${el.substring(1)}"></div>`))

  return createVueAppByConfig(vueConfig)
}

/**
 * @param {VueConfigCustom} vueConfig
 */
export const createVueApp = (vueConfig) => {
  return isElementExists(vueConfig.el)
    ? createVueAppByConfig(vueConfig)
    : initVueInBody(vueConfig)
}
