import SoftphoneHandler from './softphone_handler.vue'
import NavbarRightIcons from '@/vue_components/navbar_right_icons.vue'
import { createVueApp } from '@/vue_components/create_vue_app'

export default {
  init () {
    const softphoneHandlerExists = document.querySelector('#softphone-handler')

    if (gon.application.current_user && softphoneHandlerExists) {
      const windowSize = window.matchMedia('(max-width: 768px)')

      if (windowSize.matches) {
        $('#navbar-icons-left').children().not(':first').remove()
        const container = document.createElement('div')
        container.id = 'right-icons'
        const parent = window.document.getElementById('navbar-icons-left')
        $(container).insertAfter(parent.firstChild)

        return createVueApp({
          el: '#right-icons',
          render: (h) => h(NavbarRightIcons),
          useChildren: true,
        })
      }

      return createVueApp({
        el: '#softphone-handler',
        name: 'SoftphoneHandlerRoot',
        render: (h) => h(SoftphoneHandler),
      })
    }
  },
}
