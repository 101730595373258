<template>
  <div class="employees-rating-section">
    <si>
      <si-control
        class="hidden-print"
        :disabled="waitingLoadEmploeesRating"
        @reset="resetSortParams"
      >
        <date-range-picker-btn
          v-model="selectedDateRange"
          class="si-control-item hidden-print"
          :disabled="waitingLoadEmploeesRating"
          :buttons="dateRangeButtons"
          :unpressed="true"
        />

        <date-range-picker
          v-model="selectedDateRange"
          class="si-control-item hidden-print"
          :disabled="waitingLoadEmploeesRating"
          :application-date-format="dateForamt"
        />

        <reusable-doctor-clinics-list
          :value="selectedClinic"
          :disabled="waitingLoadEmploeesRating"
          class="mr-5"
          label=""
          :m-fixed-height="false"
          @onClinicChange="setClinic"
        />

        <reusable-specialties-list
          :value="selectedSpecialty"
          :disabled="waitingLoadEmploeesRating"
          label=""
          class="mr-5"
          :m-fixed-height="false"
          @onSpecialtyChange="setSpecialty"
        />

        <reusable-doctors-list
          :value="selectedDoctor"
          :disabled="waitingLoadEmploeesRating"
          label=""
          @onDoctorChange="setDoctor"
        />

        <template #refresh>
          <span />
        </template>
      </si-control>
      <si-table
        :spinner="waitingLoadEmploeesRating"
        :error="loadingError"
        :not-found="!GET_DOCTORS_RATING.length"
      >
        <template #header>
          <si-field>
            <div class="rating">
              {{ t('rating') }}
            </div>
            <div class="average-assessment">
              {{ t('reports.average_assessment') }}
            </div>
            <div>
              {{ t('doctor') }}
            </div>
          </si-field>
        </template>
        <template #not-found-message>
          {{ t('client_feedbacks.employees_rating.not_found') }}
        </template>
        <template #body>
          <si-field
            v-for="(line, index) in GET_DOCTORS_RATING"
            :key="index"
            class="clickable"
            @safe-click="switchToAdmissionAssessmentTab(line.doctor)"
          >
            <div class="rating">
              <span>
                {{ line.doctor.rank.value }}
                <trend-arrow
                  :value="line.doctor.rank.trend"
                  :title="line.doctor.rank.trend"
                  class="tooltip-bottom"
                  up-class="tend-arrow tend-arrow-up fa-long-arrow-alt-right"
                  down-class="tend-arrow tend-arrow-down fa-long-arrow-alt-right"
                />
              </span>
            </div>
            <div class="average-assessment">
              {{ averageAssessment(line.doctor.average_score.value) }}
              <trend-arrow
                :value="line.doctor.average_score.trend"
                :title="averageAssessment(line.doctor.average_score.trend)"
                class="tooltip-bottom"
                up-class="tend-arrow tend-arrow-up fad fa-long-arrow-alt-right"
                down-class="tend-arrow tend-arrow-down fad fa-long-arrow-alt-right"
              />
            </div>
            <div>
              <user-avatar
                :user-id="line.doctor.id"
                :params="{version: 'thumb16'}"
                class="hidden-print"
              />
              <span> {{ $filters.fullName(line.doctor) }} </span>
            </div>
          </si-field>
        </template>
      </si-table>
      <si-footer class="hidden-print">
        <pagination
          layout="prev, pager, next"
          :page-size="paginationPageSize"
          :page-count="paginationPageCount"
          :current-page.sync="paginationCurrentPage"
        />
        <div class="rating-calculation-information">
          {{ t('client_feedbacks.employees_rating.rating_calculation_information') }}
        </div>
      </si-footer>
    </si>
  </div>
</template>

<script>
import UserAvatar from '@/vue_components/user_avatar.vue'
import TrendArrow from '@/vue_components/trend_arrow.vue'
import DateRangePicker from '../../common/select/date_range_picker.vue'
import DateRangePickerBtn from '../../common/select/date_range_picker_btn.vue'
import Si from '@/vue_components/sort_items/si.vue'
import SiControl from '@/vue_components/sort_items/si_control.vue'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import Pagination from '@/vue_components/common/pagination.vue'

import { mapActions, mapGetters } from 'vuex'

import { PERSISTENT_STORAGE_KEY } from './consts.js'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import ReusableSpecialtiesList from '@/vue_present/Reuse/Lists/Specialties/ReusableSpecialtiesList.vue'
import ReusableDoctorClinicsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorClinicsList.vue'
import { FEEDBACKS_ROUTE_NAMES } from '@/vue_components/client_feedbacks/router/routeNames'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

const PAGINATION_CURRENT_PAGE_DEFAULT = 1

const DEFAULT_LOCAL_STATE = Object.freeze({
  SELECTED_CLINIC: null,
  SELECTED_SPECIALTY: null,
  SELECTED_DOCTOR: null,
  SELECTED_DATE_RANGE: [
    moment().startOf('month').toDate(),
    moment().endOf('month').toDate(),
  ],
})

const localState = window.store.get(PERSISTENT_STORAGE_KEY) || {}

export default {
  name: 'EmployeesRating',

  components: {
    Si,
    SiControl,
    SiFooter,
    SiTable,
    SiField,
    TrendArrow,
    DateRangePicker,
    DateRangePickerBtn,
    Pagination,
    UserAvatar,
    ReusableDoctorsList,
    ReusableSpecialtiesList,
    ReusableDoctorClinicsList,
  },

  data () {
    return {
      localState: {
        selectedClinic:
              localState.selectedClinic === undefined
                ? DEFAULT_LOCAL_STATE.SELECTED_CLINIC
                : localState.selectedClinic,

        selectedSpecialty:
              localState.selectedSpecialty === undefined
                ? DEFAULT_LOCAL_STATE.SELECTED_SPECIALTY
                : localState.selectedSpecialty,

        selectedDoctor:
              localState.selectedDoctor === undefined
                ? DEFAULT_LOCAL_STATE.SELECTED_DOCTOR
                : localState.selectedDoctor,

        selectedDateRange:
              Array.isArray(localState.selectedDateRange)
                ? [moment(localState.selectedDateRange[0]).toDate(),
                    moment(localState.selectedDateRange[1]).toDate()]
                : localState.selectedDateRange,
      },
      loadingError: false,
      waitingLoadEmploeesRating: false,
      paginationPageSize: 25,
      paginationCurrentPage: PAGINATION_CURRENT_PAGE_DEFAULT,
      paginationPageCount: 0,
      dateForamt: gon.localization.date_format,
      dateRangeButtons: [
        {
          id: 1,
          title: moment().subtract(1, 'months').format('MMMM'),
          range: () => [
            moment().subtract(1, 'months').startOf('month').toDate(),
            moment().subtract(1, 'months').endOf('month').toDate(),
          ],
        },
        {
          id: 2,
          title: moment().format('MMMM'),
          range: () => [
            moment().startOf('month').toDate(),
            moment().endOf('month').toDate(),
          ],
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      GET_DOCTORS_RATING: 'GET_DOCTORS_RATING',
    }),

    selectedClinic () {
      return this.localState.selectedClinic
    },

    selectedSpecialty () {
      return this.localState.selectedSpecialty
    },

    selectedDoctor () {
      return this.localState.selectedDoctor
    },

    selectedDateRange: {
      set (dateRange) {
        this.localState.selectedDateRange = dateRange
      },
      get () {
        return this.localState.selectedDateRange
      },
    },

    sortParams () {
      return {
        page: this.paginationCurrentPage,
        per_page: this.paginationPageSize,
        ...this.sortFilters,
      }
    },

    sortFilters () {
      const params = {}

      if (this.selectedClinic?.id) {
        params.clinic_id = this.selectedClinic.id
      }

      if (this.selectedSpecialty?.id) {
        params.specialty_id = this.selectedSpecialty.id
      }

      if (this.selectedDoctor?.id) {
        params.doctor_id = this.selectedDoctor.id
      }

      if (this.selectedDateRange) {
        params.start_date = this.selectedDateRange[0]
        params.end_date = this.selectedDateRange[1]
      }

      return params
    },
  },

  watch: {
    sortFilters () {
      this.paginationCurrentPage = PAGINATION_CURRENT_PAGE_DEFAULT
    },

    sortParams () {
      this.getRating()
    },

    waitingLoadEmploeesRating (bool) {
      if (bool) this.loadingError = false
    },

    localState: {
      deep: true,
      handler (value) {
        window.store.set(PERSISTENT_STORAGE_KEY, value)
      },
    },
  },

  mounted () {
    this.getRating()
  },

  methods: {
    ...mapActions({
      LOAD_DOCTORS_RATING: 'LOAD_DOCTORS_RATING',
    }),

    averageAssessment (assessment) {
      const [integer, decimal] = assessment.toString().split('.')

      return (!decimal || decimal === '0') ? integer : assessment
    },

    setSpecialty (specialty) {
      this.localState.selectedSpecialty = specialty
    },

    setClinic (clinic) {
      this.localState.selectedClinic = clinic
    },

    setDoctor (doctor) {
      this.localState.selectedDoctor = doctor
    },

    getRating () {
      this.waitingLoadEmploeesRating = true

      return this
        .LOAD_DOCTORS_RATING(this.sortParams)
        .then((res) => {
          this.paginationPageCount = res.total_pages
          this.$nextTick(Utils.updateTooltips)
        })
        .catch((err) => {
          this.loadingError = true
          console.error(err)
        })
        .finally(() => {
          this.waitingLoadEmploeesRating = false
        })
    },

    switchToAdmissionAssessmentTab (employee) {
      this.$router.push({
        name: FEEDBACKS_ROUTE_NAMES.ADMISSION_ASSESSMENT,
        params: {
          employee: {
            ...employee,
            shortName: vueFilters.shortName(employee),
          },
        },
      })
    },

    resetSortParams () {
      this.setClinic(DEFAULT_LOCAL_STATE.SELECTED_CLINIC)
      this.setSpecialty(DEFAULT_LOCAL_STATE.SELECTED_SPECIALTY)
      this.setDoctor(DEFAULT_LOCAL_STATE.SELECTED_DOCTOR)
      this.selectedDateRange = DEFAULT_LOCAL_STATE.SELECTED_DATE_RANGE
    },
  },

}
</script>
