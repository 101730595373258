<template />

<script>
export default {
  name: 'ApplicationNotificator',
  props: {
    position: {
      type: String,
      default: 'bottom-right',
    },

    customClass: {
      type: String,
      default: 'hidden-print',
    },
  },
  methods: {
    error (message, title = t('Notificator.titles.error'), duration = 15000) {
      setTimeout(() => {
        this.$notify.error({
          title,
          message,
          duration,
          position: this.position,
          customClass: this.customClass,
        })
      })
    },

    info (message, title = t('Notificator.titles.info'), duration = 10000) {
      setTimeout(() => {
        this.$notify.info({
          title,
          message,
          duration,
          position: this.position,
          customClass: this.customClass,
        })
      })
    },

    warning (message, title = t('Notificator.titles.warning'), duration = 10000) {
      setTimeout(() => {
        this.$notify.warning({
          title,
          message,
          duration,
          position: this.position,
          customClass: this.customClass,
        })
      })
    },

    success (message, title = t('Notificator.titles.success'), duration = 10000) {
      setTimeout(() => {
        this.$notify.success({
          title,
          message,
          duration,
          position: this.position,
          customClass: this.customClass,
        })
      })
    },
  },
}
</script>
