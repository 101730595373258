<template>
  <el-popover
    v-model="forcePopover"
    :append-to-body="true"
    width="305"
    placement="bottom-end"
    offset="10"
  >
    <softphone ref="poperSoftphone" />

    <li
      id="softphone-handler"
      slot="reference"
      @click="refreshSoftPhone"
    >
      <i class="fad fa-fw fa-phone-alt" />{{ T.telephony.outcoming_call }}
    </li>
  </el-popover>
</template>

<script>
import Softphone from './softphone.vue'

export default {
  name: 'SoftphoneHandler',
  components: {
    Softphone,
  },
  data () {
    return {
      forcePopover: false,
    }
  },
  mounted () {
    // 7jt3w2 - connect softphone styles to popper
    const softPhonePopper = document.querySelector('#softphone').parentNode
    softPhonePopper.classList.add('softphone-popper-panel')
  },
  methods: {
    // 7jt3w2 - reset caller after click
    refreshSoftPhone () {
      this.$refs.poperSoftphone.onRefresh()
    },
  },
}
</script>
