<template>
  <m-modal
    :visible="visible"
    :dialog-title="modalConfig.title"
    :type="modalConfig.type"
    width="620px"
    @close="close"
  >
    <template #body>
      <m-input
        v-model="diseaseStringParts.code"
        :label="t('code')"
        :required="lengthLimitValidator"
        validator-name="disease-code"
        @registerValidator="onRegisterValidator"
      />
      <m-input
        v-model="diseaseStringParts.title"
        :label="t('title')"
        :required="lengthLimitValidator"
        validator-name="disease-title"
        @registerValidator="onRegisterValidator"
      />
    </template>

    <template #footer-right-action>
      <m-button
        :text="submitBtnConfig.text"
        :type="submitBtnConfig.type"
        @click="onSubmit"
      />
    </template>
  </m-modal>
</template>

<script lang="ts">
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { IDisease } from './types'
import { LazyTreeNode } from '@/vue_present/Reuse/LazyTree/store/LazyTreeNode'
import { MDiseasePresenter } from '@/_api/catalogs/Disease/MDiseasePresenter'
import { extractLeafNodeId } from './helpers'
import { defineComponent } from 'vue'
import { MAX_DISEASE_TITLE_LENGTH } from './const'

export default defineComponent({
  name: 'DiseasesEditor',

  components: {
    MModal,
    MInput,
    MButton,
  },

  mixins: [ValidationParentMixin],

  props: {
    lastContextNode: { type: LazyTreeNode, required: true },
    presenter: { type: MDiseasePresenter, required: true },
  },

  emits: [
    'onSuccessSubmitted',
    'registerEditorApi',
  ],

  data () {
    return {
      visible: false,
      isUpdateMode: false,
      diseaseStringParts: {
        code: '',
        title: '',
      },
    }
  },

  computed: {
    modalConfig () {
      return this.isUpdateMode
        ? {
            title: t('rename'),
            type: 'warning',
          }
        : {
            title: t('categoriesActions.newItem'),
            type: 'success',
          }
    },

    submitBtnConfig () {
      return this.isUpdateMode
        ? {
            type: 'warning',
            text: t('confirm'),
          }
        : {
            type: 'success',
            text: t('add'),
          }
    },
  },

  mounted () {
    this.$emit('registerEditorApi', {
      open: this.open,
      close: this.close,
    })
  },

  methods: {
    lengthLimitValidator (value: string): string[] {
      if (!value) { return [t('errors.filled?')] }

      return value.length > MAX_DISEASE_TITLE_LENGTH
        ? [t('errors.messages.too_long.many', { count: MAX_DISEASE_TITLE_LENGTH })]
        : []
    },

    open () {
      this.visible = true
      this.resetValidations()

      this.lastContextNode.isLeaf
        ? this.switchToUpdateMode()
        : this.switchToCreateMode()
    },

    close () {
      this.visible = false
    },

    switchToUpdateMode () {
      this.isUpdateMode = true
      this.diseaseStringParts.code = this.lastContextNode.extraAttributes.codeString
      this.diseaseStringParts.title = this.lastContextNode.title
    },

    switchToCreateMode () {
      this.isUpdateMode = false
      this.diseaseStringParts.code = ''
      this.diseaseStringParts.title = ''
    },

    async onSubmit () {
      if (this.hasErrors()) { return }

      const diseaseToSubmit: IDisease = {
        id: null,
        title: this.diseaseStringParts.title.trim(),
        codeString: this.diseaseStringParts.code.trim(),
        categoryId: this.lastContextNode.id,
      }

      if (this.isUpdateMode) {
        diseaseToSubmit.id = extractLeafNodeId(this.lastContextNode)
        diseaseToSubmit.categoryId = this.lastContextNode.parent.id
      }

      const res = await this.presenter.submit(diseaseToSubmit)
      if (res?.errors) { return }

      this.$emit('onSuccessSubmitted', res, this.isUpdateMode)
    },
  },
})
</script>
