<template>
  <div class="payment-refund-form">
    <epic-spinner
      :visibility="loading"
    />

    <flex-form
      :fields="flexForms.summary"
    >
      <template #customer-value>
        {{ customer }}
      </template>

      <template #payment-kind-value>
        {{ t('_payment_kind.order_refund') }}
      </template>
    </flex-form>
    <flex-form
      :fields="flexForms.actions"
    >
      <template #refund-sum-value>
        {{ $filters.currency(sumToRefund) }}
      </template>

      <template #comment-value>
        <el-input
          type="textarea"
          :value="comment"
          :rows="2"
          :placeholder="t('comment')"
          :disabled="loading"
          @input="$emit('update:comment', $event)"
        />
      </template>
    </flex-form>
  </div>
</template>

<script>
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import FlexForm from '@/vue_components/common/flex_form.vue'

export default {
  name: 'PaymentsMassRefundForm',

  components: {
    EpicSpinner,
    FlexForm,
  },

  props: {
    customer: {
      type: String,
      required: true,
    },
    sumToRefund: {
      type: Number,
      required: true,
    },
    comment: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      flexForms: {
        summary: Object.freeze([
          { name: 'customer', label: t('cuctomer_signature') },
          { name: 'payment-kind', label: t('payment_kind') },
        ]),
        actions: Object.freeze([
          { name: 'refund-sum', label: t('refunding_sum') },
          { name: 'comment', label: t('comment') },
        ]),
      },
    }
  },

  methods: {
    update (prop, value) {
      this.$emit(`update:${prop}`, value)
    },
  },
}
</script>
