<template>
  <div class="changelog-container__viewer">
    <div class="viewer-header-container">
      <div
        v-if="closeBtnVisible"
        class="viewer-close-container"
      >
        <div
          @click="$emit('update:changelogEntry', { id: null }) && $emit('redirectBack')"
        >
          <i class="viewer-close__icon fas fa-arrow-left" />
          <span class="viewer-close__text">{{ t('back') }}</span>
        </div>
      </div>

      <div class="viewer-header__title">
        {{ title }}
      </div>

      <div class="viewer-header__labels">
        <!-- Типы -->
        <div
          v-for="(name, index) in types"
          :key="index"
          class="changelog-badge"
          :style="{ backgroundColor: getBadgeBackgroundColorByName(name) }"
        >
          {{ name }}
        </div>

        <!-- Ярлыки -->
        <div
          v-for="item in labels"
          :key="item.id"
          class="changelog-badge"
          :style="{ backgroundColor: getBadgeBackgroundColorByName(item.name) }"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

    <div class="viewer__viewer">
      <div ref="mdViewer" />
    </div>
  </div>
</template>

<script>
import markdownit from 'markdown-it'
import { getBadgeBackgroundColorByName } from '@/vue_components/support/const/methods'

const md = markdownit()

export default {
  name: 'ChangelogViewer',
  components: {

  },

  props: {
    closeBtnVisible: {
      type: Boolean,
      default: false,
    },

    title: { type: String, default: '' },
    labels: { type: Array, default: () => [] },
    types: { type: Array, default: () => [] },
    markdownDetails: { type: String, default: '' },
  },

  data () {
    return { getBadgeBackgroundColorByName }
  },

  computed: {
    first20Symbols () {
      return this.markdownDetails.slice(0, 20)
    },
  },

  watch: {
    markdownDetails () {
      if (!this.$refs.mdViewer) { return }
      this.$nextTick(() => {
        this.$refs.mdViewer.innerHTML = md.render(this.markdownDetails)
      })
    },
  },
}
</script>
