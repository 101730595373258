<template>
  <panel-heading
    :title="`${t('info_company')} - ${t('orders_registries')}`"
    icon="fad fa-cabinet-filing"
    class="company-router-container"
  >
    <div class="company-registry-list">
      <si show-tooltips>
        <si-control
          :disabled="fetched.ordersRegistries.loading"
        >
          <div class="company-registry-controls">
            <button
              v-tooltip="t('create_registry')"
              class="btn btn-success btn-sm hidden-print clients-options-btn"
              type="button"
              @click="modal.createRegistry.visibility = true"
            >
              <span class="fad fa-cabinet-filing btn-icon-left" />
              <span class="fas fa-fw fa-plus" />
            </button>
            <search-input
              :value.sync="fetched.ordersRegistries.searchQuery"
              :placeholder="t('number_or_title_registry')"
              class="search"
            />
          </div>
          <template #refresh>
            <span />
          </template>
          <template #reset>
            <span />
          </template>
        </si-control>
        <si-table
          :spinner="fetched.ordersRegistries.loading"
          :error="fetched.ordersRegistries.error"
          :not-found="!fetched.ordersRegistries.list.length"
        >
          <template #header>
            <si-field>
              <div class="number">
                {{ t('common.number') }}
              </div>
              <div class="title">
                {{ t('title') }}
              </div>
              <div class="description">
                {{ t('note') }}
              </div>
              <div class="options" />
            </si-field>
          </template>
          <template #body>
            <si-field
              v-for="registry in fetched.ordersRegistries.list"
              :key="registry.id"
              class="clickable"
              @safe-click="directToRegistryPage(registry.id)"
              @contextmenu.prevent="(e) => openCtxMenu(e, registry.id)"
            >
              <div class="number">
                {{ registry.id }}
              </div>
              <div class="title">
                {{ registry.title }}
              </div>
              <div class="description">
                {{ registry.note }}
              </div>
              <div
                v-tooltip="t('edit')"
                class="options ignore-click edit-options"
                @click="editRegistry(registry.id)"
              >
                <span
                  class="fad fa-edit ctx-icon ignore-click"
                />
              </div>
              <div
                v-tooltip="t('delete')"
                class="options ignore-click delete-options"
                @click="confirmRemove(registry.id)"
              >
                <span
                  class="fad fa-trash ctx-icon ignore-click"
                />
              </div>
            </si-field>
          </template>
        </si-table>
        <si-footer>
          <pagination
            layout="prev, pager, next"
            :page-size="pagination.pageSize"
            :page-count="pagination.pageCount"
            :current-page.sync="pagination.currentPage"
          />
        </si-footer>
      </si>
    </div>

    <ctx-menu ref="ctx">
      <ctx-item
        :underline="true"
        @select="newTabRegistry"
      >
        <template #title>
          <span class="fad fa-external-link-square ctx-icon" />
          {{ t('open_new_tab') }}
        </template>
      </ctx-item>
      <ctx-item
        :underline="false"
        @select="confirmRemove(ctxSelectedRegistryId)"
      >
        <template #title>
          <span class="fad fa-trash ctx-icon" />
          {{ t('delete') }}
        </template>
      </ctx-item>
    </ctx-menu>

    <modal
      :modal-visibility="modal.createRegistry.visibility"
      @close="closeModal('createRegistry')"
    >
      <template #header>
        <span class="fad fa-cabinet-filing" />
        {{ t('create_registry') }}
      </template>

      <template #body>
        <epic-spinner
          :visibility="modal.createRegistry.loading"
        />

        <flex-form
          :fields="flexForm.registry"
        >
          <template #title-value>
            <validation-wrapper
              :errors="userInput.title.validationMessages"
            >
              <el-input v-model="userInput.title.value" />
            </validation-wrapper>
          </template>

          <template #note-value>
            <validation-wrapper
              :errors="userInput.note.validationMessages"
            >
              <el-input
                v-model="userInput.note.value"
                type="textarea"
                :rows="3"
              />
            </validation-wrapper>
          </template>
        </flex-form>
      </template>

      <template #footer-right>
        <button
          :disabled="modal.createRegistry.spinner"
          class="btn btn-success btn-with-icon modal-save"
          type="button"
          @click="createOrderRegistry"
        >
          <div class="btn-with-icon_icon fad fa-save" />
          <div class="btn-with-icon_text">
            {{ t('save') }}
          </div>
        </button>
        <button
          :disabled="modal.createRegistry.spinner"
          class="btn btn-primary btn-with-icon modal-close"
          type="button"
          @click="closeModal('createRegistry')"
        >
          <div class="btn-with-icon_icon fad fa-times" />
          <div class="btn-with-icon_text">
            {{ t('close') }}
          </div>
        </button>
      </template>
    </modal>

    <modal
      v-if="modal.editRegistry.visibility"
      :modal-visibility="modal.editRegistry.visibility"
      @close="closeModal('editRegistry')"
    >
      <template #header>
        <span class="fad fa-cabinet-filing" />
        {{ t('edit_registry') }}
      </template>

      <template #body>
        <epic-spinner
          :visibility="modal.editRegistry.loading"
        />

        <flex-form
          :fields="flexForm.registry"
        >
          <template #title-value>
            <validation-wrapper
              :errors="userInput.title.validationMessages"
            >
              <el-input v-model="userInput.title.value" />
            </validation-wrapper>
          </template>

          <template #note-value>
            <validation-wrapper
              :errors="userInput.note.validationMessages"
            >
              <el-input
                v-model="userInput.note.value"
                type="textarea"
                :rows="5"
              />
            </validation-wrapper>
          </template>
        </flex-form>
      </template>

      <template #footer-right>
        <button
          :disabled="modal.editRegistry.spinner"
          class="btn btn-success btn-with-icon modal-save"
          type="button"
          @click="updateOrdersRegistry"
        >
          <div class="btn-with-icon_icon fad fa-save" />
          <div class="btn-with-icon_text">
            {{ t('save') }}
          </div>
        </button>
        <button
          :disabled="modal.editRegistry.spinner"
          class="btn btn-primary btn-with-icon modal-close"
          type="button"
          @click="closeModal('editRegistry')"
        >
          <div class="btn-with-icon_icon fad fa-times" />
          <div class="btn-with-icon_text">
            {{ t('close') }}
          </div>
        </button>
      </template>
    </modal>

    <modal
      v-if="modal.removeRegistry.visibility"
      :modal-visibility="modal.removeRegistry.visibility"
      modal-size="md"
      @close="modal.removeRegistry.visibility = false"
    >
      <span slot="header">
        {{ t('confirm_delete') }}
      </span>

      <span slot="body">
        {{ modal.removeRegistry.message }}
      </span>

      <template slot="footer-right">
        <button
          class="btn btn-sm btn-danger btn-with-icon modal-save"
          @click="deleteRegistry(modal.removeRegistry.registryId)"
        >
          <span
            class="btn-with-icon_icon fad fa-exclamation-circle"
          />
          <span
            class="btn-with-icon_text"
          >
            {{ t('delete') }}
          </span>
        </button>

        <button
          class="btn btn-sm btn-primary modal-close"
          @click="modal.removeRegistry.visibility = false"
        >
          <span class="btn-with-icon_icon fad fa-times" />
          <span class="btn-with-icon_text">
            {{ t('cancel') }}
          </span>
        </button>
      </template>
    </modal>
  </panel-heading>
</template>

<script>
import CtxMenu from '@/vue_components/common/ctx_menu/ctx_menu'
import CtxItem from '@/vue_components/common/ctx_menu/ctx_item'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import FlexForm from '@/vue_components/common/flex_form.vue'
import Modal from '@/vue_components/modal.vue'
import Pagination from '@/vue_components/common/pagination.vue'
import PanelHeading from '@/vue_components/common/panel_heading.vue'
import SearchInput from '@/vue_components/common/search_input.vue'
import Si from '@/vue_components/sort_items/si.vue'
import SiControl from '@/vue_components/sort_items/si_control.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'

import { PAGINATION_PAGE_SIZE } from '@/vue_components/sort_items/consts.js'
import { COMPANY_ORDERS_REGISTRY } from '@/vue_components/router/modules/companies/route_names'
import { orderRegistriesEndpoint } from '@/api_entities/companies/order_registries_endpoint'

export default {
  name: 'CompanyOrdersRegistries',

  components: {
    CtxMenu,
    CtxItem,
    EpicSpinner,
    FlexForm,
    Modal,
    Pagination,
    SearchInput,
    PanelHeading,
    Si,
    SiControl,
    SiField,
    SiFooter,
    SiTable,
    ValidationWrapper,
  },

  data () {
    return {
      ctxSelectedRegistryId: null,
      fetched: {
        ordersRegistries: {
          list: [],
          error: false,
          loading: false,
          searchQuery: '',
        },
      },

      pagination: {
        pageSize: PAGINATION_PAGE_SIZE,
        pageCount: 0,
        currentPage: 1,
      },

      modal: {
        createRegistry: {
          visibility: false,
          loading: false,
        },
        editRegistry: {
          visibility: false,
          loading: false,
          registryId: null,
        },
        removeRegistry: {
          visibility: false,
          message: t('confirm_remove_registry'),
          registryId: null,
        },
      },

      userInput: {
        title: {
          value: '',
          validationMessages: [],
        },

        note: {
          value: '',
          validationMessages: [],
        },
      },

      flexForm: {
        registry: [
          { name: 'title', label: t('title'), required: true },
          { name: 'note', label: t('additional') },
        ],
      },

    }
  },

  computed: {
    reqOrdersParams () {
      return {
        search_query: this.fetched.ordersRegistries.searchQuery,
      }
    },

    mapOrdersRegistriesIndexes () {
      const ordersRegistriesHash = Object.create(null)

      this.fetched.ordersRegistries.list.forEach((registry, index) => {
        ordersRegistriesHash[registry.id] = index
      })

      return ordersRegistriesHash
    },
  },

  watch: {
    'modal.createRegistry.visibility' (newVisibility) {
      if (newVisibility) return

      this.userInput.title.validationMessages = []
      this.userInput.note.validationMessages = []
    },

    'modal.editRegistry.visibility' (newVisibility) {
      if (newVisibility) return

      this.userInput.title.validationMessages = []
      this.userInput.note.validationMessages = []
    },

    'userInput.title.value' () {
      this.userInput.title.validationMessages = []
      this.userInput.note.validationMessages = []
    },

    'userInput.note.value' () {
      this.userInput.title.validationMessages = []
      this.userInput.note.validationMessages = []
    },

    reqOrdersParams () {
      this.findRegistries()
    },
  },

  mounted () {
    this.findRegistries()
  },

  methods: {
    findRegistries () {
      this.fetched.ordersRegistries.error = false
      this.fetched.ordersRegistries.loading = true

      orderRegistriesEndpoint.find(this.$route.params.companyId, this.reqOrdersParams)
        .then((response) => {
          this.fetched.ordersRegistries.list = [...response]
        })
        .catch((e) => {
          this.fetched.ordersRegistries.error = true
        })
        .finally(() => {
          this.fetched.ordersRegistries.loading = false
        })
    },

    createOrderRegistry () {
      this.modal.createRegistry.loading = true

      orderRegistriesEndpoint.create(this.generateRegistryData())
        .then((res) => {
          this.fetched.ordersRegistries.list.push(res)
          this.modal.createRegistry.visibility = false
          Notificator.success(t('record_successfully_created'))
          this.clearModalInputs()
        })
        .catch(this.errorServerRegistry)
        .finally(() => {
          this.modal.createRegistry.loading = false
        })
    },

    updateOrdersRegistry () {
      this.modal.editRegistry.loading = true

      orderRegistriesEndpoint.update(this.modal.editRegistry.registryId, this.generateRegistryData())
        .then((res) => {
          const { list } = this.fetched.ordersRegistries
          const index = this.mapOrdersRegistriesIndexes[res.id]
          this.$set(this.fetched.ordersRegistries.list, index, { ...list[index], ...res })

          Notificator.success(t('record_successfully_updated'))
          this.clearModalInputs()
          this.modal.editRegistry.visibility = false
          this.modal.editRegistry.registryId = null
        })
        .catch(this.errorServerRegistry)
        .finally(() => {
          this.modal.editRegistry.loading = false
        })
    },

    openCtxMenu (event, registryId) {
      this.ctxSelectedRegistryId = registryId
      this.$refs.ctx.open(event)
    },

    newTabRegistry () {
      window.open(
        this.$router.resolve({
          name: COMPANY_ORDERS_REGISTRY,
          params: { registryId: this.ctxSelectedRegistryId },
        }).href,
        '_target'
      )
    },

    editRegistry (registryId) {
      this.modal.editRegistry.loading = true
      this.modal.editRegistry.visibility = true
      this.modal.editRegistry.registryId = registryId
      orderRegistriesEndpoint.get(this.$route.params.companyId, registryId)
        .then((registry) => {
          this.userInput.title.value = registry.title
          this.userInput.note.value = registry.note
          this.modal.editRegistry.loading = false
        })
        .catch(() => Notificator.success(t('abstract_error_message')))
    },

    confirmRemove (registryId) {
      this.modal.removeRegistry.registryId = registryId
      orderRegistriesEndpoint.getOrdersCount(registryId)
        .then((res) => {
          if (res === 0) {
            this.modal.removeRegistry.message = t('confirm_remove_registry')
          } else {
            this.modal.removeRegistry.message = t('confirm_remove_registry_with_orders')
          }
          this.modal.removeRegistry.visibility = true
        })
        .catch(() => Notificator.success(t('abstract_error_message')))
    },

    deleteRegistry (registryId) {
      orderRegistriesEndpoint.destroy(registryId)
        .then((res) => {
          this.fetched.ordersRegistries.list.splice(this.mapOrdersRegistriesIndexes[res.id], 1)
          this.modal.removeRegistry.visibility = false
          this.modal.removeRegistry.registryId = null
          Notificator.success(t('record_successfully_deleted'))
        })
        .catch(() => Notificator.success(t('abstract_error_message')))
    },

    directToRegistryPage (registryId) {
      this.$router.push({ name: COMPANY_ORDERS_REGISTRY, params: { registryId } })
    },

    closeModal (modalType) {
      this.modal[modalType].visibility = false
      this.clearModalInputs()
    },

    clearModalInputs () {
      this.userInput.title.value = ''
      this.userInput.note.value = ''
    },

    errorServerRegistry (res) {
      if (res.status !== 422) {
        Notificator.error(t('abstract_error_message'))

        return
      }
      const {
        responseJSON: { title = [], note = [] },
      } = res
      this.userInput.title.validationMessages = title
      this.userInput.note.validationMessages = note
    },

    generateRegistryData () {
      return {
        title: this.userInput.title.value,
        note: this.userInput.note.value,
        company_id: this.$route.params.companyId,
      }
    },
  },
}
</script>
