export const diseaseAdapter = {
  /**
   * @param {ILazyTreeResponseData} response
   * @return {ILazyTreeResponseData}
   */
  toClient (response) {
    return {
      catalogs: response.categories.map((category) => ({
        ...category,
        title: category.parentId
          ? `${category.info} ${category.title}`
          : category.title,
      })),

      ...response,
    }
  },

  toServer (data) {
    return { disease: data }
  },
}
