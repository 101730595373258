import { CheckupEntryTypeModel } from '@/vue_apps/catalogs_root/CheckupCatalogs/classes/CheckupEntryTypeModel'
import { MCheckupEntryTypePresenter } from '@/vue_apps/catalogs_root/CheckupCatalogs/api/MCheckupEntryTypePresenter'
import { cloneDeep, pick } from 'lodash'
import { checkupCatalogAdapter } from '@/vue_apps/catalogs_root/CheckupCatalogs/api/checkupCatalogAdapter'
import { getAssembledEntryTypes } from '@/vue_present/Reuse/Lists/EntryTypes/api/getAssembledEntryTypes'

const extractUser = (user) => ({
  id: user.userId,
  shortName: user.userShortName,
  dbId: user.id,
})

export class CheckupEntryType extends CheckupEntryTypeModel {
  /**
   * @type {MCheckupEntryTypePresenter}
   * @private
   */
  _presenter = new MCheckupEntryTypePresenter()

  defaultClinicCabinetsMap = {}

  clinicCabinetsMap = {}

  async load (allowedClinics = []) {
    const data = await this._presenter.fetch(this.id)
    if (data.errors) { return }

    this._fillProps(data)

    allowedClinics.forEach((clinic) => {
      this.clinicCabinetsMap[clinic.id] = {
        cabinet: null,
        users: null,
        entryType: null,
      }
    })

    this.clinicsCabinets.forEach((clinicCabinet) => {
      this.clinicCabinetsMap[clinicCabinet.clinicId] = {
        cabinet: clinicCabinet.cabinet || null,
        users: (clinicCabinet.entryTypesClinicsCabinetsUsers || []).map(extractUser),
        entryType: (clinicCabinet.entryType && getAssembledEntryTypes(clinicCabinet.entryType)) || null,
        id: clinicCabinet.id,
      }
    })

    this.defaultClinicCabinetsMap = cloneDeep(this.clinicCabinetsMap)
  }

  async save () {
    const data = pick(this, ['id', 'text', 'clinicCabinetsMap', 'defaultClinicCabinetsMap'])

    return this._presenter.submit(data, {
      toServerAdapter: checkupCatalogAdapter.toServer,
      toJson: true,
    }).then(({ errors, clinicsCabinets }) => {
      if (errors) { return location.reload() }

      this.fillIds(clinicsCabinets)
    })
  }

  /**
   * @private
   */
  fillIds (clinicsCabinets = []) {
    clinicsCabinets.forEach((clinicsCabinet) => {
      this.clinicCabinetsMap[clinicsCabinet.clinicId].id = clinicsCabinet.id
      this.clinicCabinetsMap[clinicsCabinet.clinicId].users =
        clinicsCabinet.entryTypesClinicsCabinetsUsers.map(extractUser)
    })

    this.defaultClinicCabinetsMap = cloneDeep(this.clinicCabinetsMap)
  }
}
