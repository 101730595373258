<template>
  <div class="node-content flex align-center overflow-hidden">
    <div class="mr-5">
      <m-icon
        v-if="data.children"
        :icon="catalogIcon"
        color="warning"
      />

      <m-icon
        v-else
        :icon="itemIcon"
        color="gray"
      />
    </div>

    <m-icon
      v-if="editable"
      class="mr-5"
      icon="menu"
      color="gray"
      @click="$emit('onEdit', $event)"
    />

    <slot name="title">
      <div
        v-tooltip="data.title"
        class="node-content__title cut"
      >
        <span>{{ data.title }}</span>
      </div>
    </slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default defineComponent({
  name: 'LazyTreeNodeComponent',

  components: { MIcon },

  props: {
    node: { type: Object, required: true },
    data: { type: Object, required: true },
    itemIcon: { type: String, default: 'file' },
    editable: Boolean,
  },

  emits: ['onEdit'],

  computed: {
    catalogIcon () {
      return this.node.expanded
        ? 'folderOpen'
        : 'folder'
    },
  },
})
</script>
