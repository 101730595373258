import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { FEEDBACK_STATUSES_APPEARANCE } from '@/vue_apps/FeedbackModule/const/const'
import { IFeedbackListItemDTO } from '@/vue_apps/FeedbackModule/interfaces/IFeedbackListItemDTO'

export class FeedbackListItem extends MListServiceItem {
  status: MTableCell

  id: number

  rawData: IFeedbackListItemDTO

  client: MTableCell

  phone: MTableCell

  receivedAt: MTableCell

  doctor: MTableCell

  doctorScore: MTableCell

  clinicScore: MTableCell

  clientComment: MTableCell

  urls: MTableCell

  constructor (data?: IFeedbackListItemDTO) {
    super()

    this.status = new MTableCell()
      .addTypeIcon(FEEDBACK_STATUSES_APPEARANCE[data.status].icon)
      .addClasses(FEEDBACK_STATUSES_APPEARANCE[data.status].color)
      .addTooltip(t(data.status, { scope: 'feedback.status' }))

    this.id = data.id

    this.rawData = data

    this.client = new MTableCell(data.client.fullName)
      .addTypeUrl(Routes.client_path(data.client.id))
      .addTooltip(true)
      .addClasses(data.client.deletedAt ? 'deleted' : '')
      .addCut()

    this.phone = new MTableCell()
      .addTooltip(true)
      .addTypePhone(data.client.id, data.client?.phone)

    this.receivedAt = new MTableCell(vueFilters.date(data.receivedAt))

    this.doctor = new MTableCell(data.doctor.fullName)
      .addTooltip(true)
      .addCut()

    this.doctorScore = new MTableCell(data.doctorScore)

    this.clinicScore = new MTableCell(data.clinicScore)

    this.clientComment = new MTableCell(data.clientComment)
      .addTooltip(true)
      .addCut()

    this.urls = new MTableCell(data.urls)
  }
}
