import { MWazzupPresenter } from '@/_api/Deliveries/MWazzupPresenter'
import { MWhatsappConfigurationsPresenter } from '@/_api/Deliveries/MWhatsappConfigurationsPresenter'
import { WABA_CHANNEL_TYPE } from '@/vue_apps/app_configuration/messages/scripts/consts'

export const whatsappConfigurationsStore = {
  namespaced: true,

  state: () => ({
    wazzupChannels: [],
    wazzupTemplatesList: null,
    appointmentNotifyWabaTemplateId: null,
    notifyWabaTemplateVariables: null,
    appointmentRemindWabaTemplateId: null,
    remindWabaTemplateVariables: null,
    apiToken: null,
    channelId: null,
    channelType: null,
    newClientsDenyDefault: null,
    channelFailuresNotifications: null,
    channelFailuresUsers: null,
    appointmentConfirmationWabaTemplateId: null,
    appointmentConfirmationWabaTemplateVariables: null,
    clientsBirthdayWabaTemplateId: null,
    clientsBirthdayWabaTemplateVariables: null,
    clientsBirthdayTemplate: null,
    passwordWabaTemplateId: null,
    confirmationCodeWabaTemplateId: null,
    appointmentNotifyDeliveredWaitingTime: null,
    appointmentNotifyReadWaitingTime: null,
    appointmentRemindDeliveredWaitingTime: null,
    appointmentRemindReadWaitingTime: null,
    appointmentConfirmationDeliveredWaitingTime: null,
    appointmentConfirmationReadWaitingTime: null,
    clientsBirthdayDeliveredWaitingTime: null,
    clientsBirthdayReadWaitingTime: null,
    servicesStatusWabaTemplateId: null,
    servicesStatusWabaTemplateVariables: null,
    servicesStatusDeliveredWaitingTime: null,
    servicesStatusReadWaitingTime: null,
    clientWidgetDeliveredWaitingTime: null,
    clientWidgetReadWaitingTime: null,
    clientsFeedbackWabaTemplateId: null,
    clientsFeedbackWabaTemplateVariables: null,
    clientsFeedbackReadWaitingTime: null,
    clientsFeedbackDeliveredWaitingTime: null,
    aggregatorFeedbackWabaTemplateId: null,
    aggregatorFeedbackWabaTemplateVariables: null,
  }),

  getters: {
    vxGetWazzupTemplatesList: (state) => state.wazzupTemplatesList || [],
    vxIsWabaActive: (state) => state.channelType === WABA_CHANNEL_TYPE,

    //AppointmentNotify
    vxGetWazzupChannels: (state) => state.wazzupChannels || [],
    vxGetAppointmentNotifyWabaTemplateId: (state) => state.appointmentNotifyWabaTemplateId || '',
    vxGetAppointmentNotifyWabaTemplateVariables: (state) => state.notifyWabaTemplateVariables || [],

    //AppointmentRemind
    vxGetAppointmentRemindWabaTemplateId: (state) => state.appointmentRemindWabaTemplateId || '',
    vxGetAppointmentRemindWabaTemplateVariables: (state) => state.remindWabaTemplateVariables || [],

    //AppointmentConfirmation
    vxGetAppointmentConfirmationWabaTemplateId: (state) => state.appointmentConfirmationWabaTemplateId || '',
    vxGetAppointmentConfirmationWabaTemplateVariables: (state) => state.appointmentConfirmationWabaTemplateVariables || '',

    //CommonConfigurationsPage
    vxGetChannelId: (state) => state.channelId
      ? ({ channelId: state.channelId })
      : null,
    vxGetApiToken: (state) => state.apiToken || null,
    vxGetChannelType: (state) => state.channelType || null,
    vxGetNewClientsDenyDefault: (state) => state.newClientsDenyDefault || false,
    vxGetChannelFailuresUsers: (state) => state.channelFailuresUsers || [],
    vxGetChannelFailuresNotifications: (state) => state.channelFailuresNotifications || null,

    //ClientsBirthdayPage
    vxGetClientsBirthdayWabaTemplateId: (state) => state.clientsBirthdayWabaTemplateId,
    vxGetClientsBirthdayWabaTemplateVariables: (state) => state.clientsBirthdayWabaTemplateVariables,

    //ServicesStatusPage
    vxGetServicesStatusWabaTemplateId: (state) => state.servicesStatusWabaTemplateId || '',
    vxGetServicesStatusWabaTemplateVariables: (state) => state.servicesStatusWabaTemplateVariables,

    //ClientWidget
    vxGetWabaPasswordTemplateId: (state) => state.passwordWabaTemplateId,
    vxGetWabaConfirmationCodeTemplateId: (state) => state.confirmationCodeWabaTemplateId,

    //ClientsFeedback
    vxGetClientsFeedbackWabaTemplateVariables: (state) => state.clientsFeedbackWabaTemplateVariables,
    vxGetClientsFeedbackWabaTemplateId: (state) => state.clientsFeedbackWabaTemplateId,

    //AggregatorFeedback
    vxGetAggregatorFeedbackWabaTemplateId: (state) => state.aggregatorFeedbackWabaTemplateId,
    vxGetAggregatorFeedbackWabaTemplateVariables: (state) => state.aggregatorFeedbackWabaTemplateVariables,

    //WaitingLimits
    vxGetAppointmentNotifyDeliveredWaitingTime: (state) => state.appointmentNotifyDeliveredWaitingTime || 'PT2M',
    vxGetAppointmentNotifyReadWaitingTime: (state) => state.appointmentNotifyReadWaitingTime || 'PT0S',
    vxGetAppointmentRemindDeliveredWaitingTime: (state) => state.appointmentRemindDeliveredWaitingTime || 'PT10M',
    vxGetAppointmentRemindReadWaitingTime: (state) => state.appointmentRemindReadWaitingTime || 'PT1H',
    vxGetAppointmentConfirmationDeliveredWaitingTime: (state) => state.appointmentConfirmationDeliveredWaitingTime || 'PT10M',
    vxGetAppointmentConfirmationReadWaitingTime: (state) => state.appointmentConfirmationReadWaitingTime || 'PT1H',
    vxGetClientsBirthdayDeliveredWaitingTime: (state) => state.clientsBirthdayDeliveredWaitingTime || 'PT10M',
    vxGetClientsBirthdayReadWaitingTime: (state) => state.clientsBirthdayReadWaitingTime || 'PT1H',
    vxGetServicesStatusDeliveredWaitingTime: (state) => state.servicesStatusDeliveredWaitingTime || 'PT10M',
    vxGetServicesStatusReadWaitingTime: (state) => state.servicesStatusReadWaitingTime || 'PT1H',
    vxGetClientWidgetDeliveredWaitingTime: (state) => state.clientWidgetDeliveredWaitingTime || 'PT20S',
    vxGetClientWidgetReadWaitingTime: (state) => state.clientWidgetReadWaitingTime || 'PT2M',
    vxGetClientsFeedbackReadWaitingTime: (state) => state.clientsFeedbackReadWaitingTime || 'PT20M',
    vxGetClientsFeedbackDeliveredWaitingTime: (state) => state.clientsFeedbackDeliveredWaitingTime || 'PT0S',
  },

  mutations: {
    vxSetWazzupChannels (state, channels) {
      state.wazzupChannels = channels
    },

    vxSetWhatsappConfigurations (state, configurations) {
      Object.keys(configurations).forEach((key) => {
        state[key] = configurations[key]
      })
    },

    vxSetWazzupTemplatesList (state, templateList) {
      state.wazzupTemplatesList = templateList
    },

    vxSetAppointmentNotifyWabaTemplateId (state, value) {
      state.appointmentNotifyWabaTemplateId = value
    },

    vxSetAppointmentRemindWabaTemplate (state, value) {
      state.remindWabaTemplate = value
    },

    vxSetAppointmentRemindWabaTemplateId (state, value) {
      state.appointmentRemindWabaTemplateId = value
    },

    vxSetAppointmentConfirmationWabaTemplateId (state, value) {
      state.appointmentConfirmationWabaTemplateId = value
    },

    vxSetChannelId (state, value) {
      state.channelId = value
    },

    vxSetApiToken (state, value) {
      state.apiToken = value
    },

    vxSetChannelType (state, value) {
      state.channelType = value
    },

    vxSetNewClientsDenyDefault (state, value) {
      state.newClientsDenyDefault = value
    },

    vxSetChannelFailuresUsers (state, value) {
      state.channelFailuresUsers = value
    },

    vxSetChannelFailuresNotifications (state, value) {
      state.channelFailuresNotifications = value
    },

    vxSetClientsBirthdayWabaTemplateId (state, value) {
      state.clientsBirthdayWabaTemplateId = value
    },

    vxSetServicesStatusWabaTemplateId (state, value) {
      state.servicesStatusWabaTemplateId = value
    },

    vxSetPasswordWabaTemplateId (state, value) {
      state.passwordWabaTemplateId = value || null
    },

    vxSetConfirmationCodeWabaTemplateId (state, value) {
      state.confirmationCodeWabaTemplateId = value || null
    },

    vxSetClientsFeedbackWabaTemplateId (state, value) {
      state.clientsFeedbackWabaTemplateId = value
    },

    vxSetAggregatorFeedbackWabaTemplateId (state, value) {
      state.aggregatorFeedbackWabaTemplateId = value
    },

    vxSetAggregatorFeedbackWabaTemplateVariables (state, value) {
      state.aggregatorFeedbackWabaTemplateVariables = value
    },

    //WaitingLimits
    vxSetAppointmentNotifyDeliveredWaitingTime (state, value) {
      state.appointmentNotifyDeliveredWaitingTime = value
    },

    vxSetAppointmentNotifyReadWaitingTime (state, value) {
      state.appointmentNotifyReadWaitingTime = value
    },

    vxSetAppointmentRemindDeliveredWaitingTime (state, value) {
      state.appointmentRemindDeliveredWaitingTime = value
    },

    vxSetAppointmentRemindReadWaitingTime (state, value) {
      state.appointmentRemindReadWaitingTime = value
    },

    vxSetAppointmentConfirmationDeliveredWaitingTime (state, value) {
      state.appointmentConfirmationDeliveredWaitingTime = value
    },

    vxSetAppointmentConfirmationReadWaitingTime (state, value) {
      state.appointmentConfirmationReadWaitingTime = value
    },

    vxSetClientsBirthdayDeliveredWaitingTime (state, value) {
      state.clientsBirthdayDeliveredWaitingTime = value
    },

    vxSetClientsBirthdayReadWaitingTime (state, value) {
      state.clientsBirthdayReadWaitingTime = value
    },

    vxSetServicesStatusDeliveredWaitingTime (state, value) {
      state.servicesStatusDeliveredWaitingTime = value
    },

    vxSetServicesStatusReadWaitingTime (state, value) {
      state.servicesStatusReadWaitingTime = value
    },

    vxSetClientWidgetDeliveredWaitingTime (state, value) {
      state.clientWidgetDeliveredWaitingTime = value
    },

    vxSetClientWidgetReadWaitingTime (state, value) {
      state.clientWidgetReadWaitingTime = value
    },

    vxSetClientsFeedbackReadWaitingTime (state, value) {
      state.clientsFeedbackReadWaitingTime = value
    },

    vxSetClientsFeedbackDeliveredWaitingTime (state, value) {
      state.clientsFeedbackDeliveredWaitingTime = value
    },
  },

  actions: {
    async vxFetchWazzupChannels ({ commit }) {
      const presenter = new MWazzupPresenter()
      const channels = await presenter.fetchWazzupChannels()
      if (channels?.errors) { return }

      commit('vxSetWazzupChannels', channels)
    },

    async vxFetchWhatsappConfigurations ({ commit }) {
      const presenter = new MWhatsappConfigurationsPresenter()
      const configurations = await presenter.fetchWhatsappConfigurations()
      if (configurations?.errors) { return }

      commit('vxSetWhatsappConfigurations', configurations)
    },

    async vxFetchWazzupTemplatesList ({ commit }) {
      const presenter = new MWazzupPresenter()

      const templatesList = await presenter.fetchWazzupTemplates()
      if (templatesList?.errors) { return }

      commit('vxSetWazzupTemplatesList', templatesList)
    },
  },
}
