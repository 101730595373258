import { createVueApp } from '@/vue_components/create_vue_app'
import EpicSpinnerApp from '@/vue_components/epic_spinner/EpicSpinnerApp.vue'

export default {
  init () {
    const container = document.createElement('div')
    container.id = 'epic-spinner-container'
    window.document.body.appendChild(container)
    $(container).loadSpin('start').loadSpin('stop')

    return createVueApp({
      el: '#epic-spinner-container',
      render: (h) => h(EpicSpinnerApp),
      useChildren: true,
    })
  },
}
