import { isEmpty } from 'lodash'
import { TDateLike } from '@/_declarations/TDateLike'
import { TDatePeriod } from '@/_declarations/TDatePeriod'
import { TDateLikePeriod } from '@/_declarations/TDateLikePeriod'
import { TDateString } from '@/_declarations/TDateString'

export class DateHelpers {
  static leapDayIndexInYear: number = 60

  /* Вчера, Сегодня, Завтра */
  static getTodayTomorrowYesterday (
    date: TDateLike,
    withTime: Boolean = false
  ): { formattedDate: string; dateCss: string } {
    const targetDate = moment(date).startOf('day')
    const currentDate = moment().startOf('day')
    const time = Utils.ternary(withTime, moment(date).format('HH:mm')) || ''

    const dateDiff = targetDate.diff(currentDate, 'days')
    switch (dateDiff) {
      case 0: return { formattedDate: `${t('today')} ${time}`, dateCss: 'date_today' }
      case -1: return { formattedDate: `${t('yesterday')} ${time}`, dateCss: 'date_yesterday' }
      case 1: return { formattedDate: `${t('tomorrow')} ${time}`, dateCss: 'date_tomorrow' }
      default: return { formattedDate: `${Utils.getFormattedDate(targetDate)} ${time}`, dateCss: '' }
    }
  }

  static toBasePeriod (
    period: TDateLikePeriod,
    base: string = Utils.baseDateFormat
  ): TDatePeriod | [] {
    if (isEmpty(period)) { return [] }

    return [
      Utils.getFormattedDate(period[0], base),
      Utils.getFormattedDate(period[1], base),
    ]
  }

  static toLegacyPeriod (period: TDateLikePeriod): { period: TDateString } {
    return { period: DateHelpers.toBasePeriod(period, Utils.longDateFormat).join(' - ') }
  }

  /* Набор функций, возвращающих один из ключевых периодов в baseDateFormat. */
  static getTodayPeriod (): TDatePeriod {
    const today = Utils.getBaseFormattedDate()

    return [today, today]
  }

  static getYesterdayPeriod (): TDatePeriod {
    const yesterday = Utils.getBaseFormattedDate(moment().subtract(1, 'days'))

    return [yesterday, yesterday]
  }

  static getWeekPeriod (): TDatePeriod {
    return [
      Utils.getBaseFormattedDate(moment().subtract(6, 'days')),
      Utils.getBaseFormattedDate(),
    ]
  }

  static getThirtyDaysPeriod (): TDatePeriod {
    return [
      Utils.getBaseFormattedDate(moment().subtract(29, 'days')),
      Utils.getBaseFormattedDate(),
    ]
  }

  static getNinetyDaysPeriod (): TDatePeriod {
    return [
      Utils.getBaseFormattedDate(moment().subtract(89, 'days')),
      Utils.getBaseFormattedDate(),
    ]
  }

  static getCurrentMonthPeriod (): TDatePeriod {
    return [
      Utils.getBaseFormattedDate(moment().startOf('month')),
      Utils.getBaseFormattedDate(moment().endOf('month')),
    ]
  }

  static getPreviousMonthPeriod (): TDatePeriod {
    return [
      Utils.getBaseFormattedDate(moment().startOf('month').subtract(1, 'month')),
      Utils.getBaseFormattedDate(moment().endOf('month').subtract(1, 'month')),
    ]
  }

  static getCurrentYearPeriod (): TDatePeriod {
    return [
      Utils.getBaseFormattedDate(moment().startOf('year')),
      Utils.getBaseFormattedDate(moment().endOf('year')),
    ]
  }

  static getPreviousYearPeriod (): TDatePeriod {
    return [
      Utils.getBaseFormattedDate(moment().startOf('year').subtract(1, 'year')),
      Utils.getBaseFormattedDate(moment().endOf('year').subtract(1, 'year')),
    ]
  }
  /* --- */

  /**
   * Возвращает период === текущий месяц
   * @deprecated Использовать DateHelpers.getCurrentMonthPeriod
   */
  static getMonthPeriod (): [Date, Date] {
    return [
      moment().startOf('month').toDate(),
      moment().endOf('month').toDate(),
    ]
  }

  /**
   * Возвращает период === текущий год
   * @deprecated Использовать DateHelpers.getCurrentYearPeriod
   */
  static getYearPeriod (): [Date, Date] {
    return [
      moment().startOf('year').toDate(),
      moment().endOf('year').toDate(),
    ]
  }

  /* Возвращает количество високосных дней между датами. */
  static getCountOfLeapDaysBetweenDates (minDate: TDateLike, maxDate: TDateLike): number {
    const momentMinDate = moment(minDate)
    const momentMaxDate = moment(maxDate)

    const minDateYear = momentMinDate.year()
    const maxDateYear = momentMaxDate.year()

    return Array.from({ length: maxDateYear - minDateYear + 1 })
      .reduce((acc: number, _, idx: number) => {
        const year = moment(minDateYear + idx, 'YYYY')

        if (!year.isLeapYear()) { return acc }

        const leapDayDate = year.dayOfYear(DateHelpers.leapDayIndexInYear)

        return acc + Number(leapDayDate.isBetween(momentMinDate, momentMaxDate))
      }, 0) as number
  }

  /* Проверяет, обозначают ли две даты один и тот же день. */
  static areSameDays (firstDate: TDateLike, secondDate: TDateLike): Boolean {
    return moment(firstDate).isSame(moment(secondDate), 'days')
  }

  /* Проверяет равенство периодов. */
  static areSamePeriods (
    firstPeriod: TDateLikePeriod,
    secondPeriod: TDateLikePeriod
  ): Boolean {
    return DateHelpers.areSameDays(firstPeriod[0], secondPeriod[0]) &&
      DateHelpers.areSameDays(firstPeriod[1], secondPeriod[1])
  }

  /* Переводит строку вида "1 сентября 2000 - 23 ноября 2042" в ["2000-09-01", "2042-11-23"]. */
  static fromLegacyDatepickerFormatToBase (periodString: string): TDatePeriod {
    const [startPeriod, endPeriod] = periodString.split(' - ')

    return [
      Utils.getBaseFormattedDate(startPeriod, Utils.longDateFormat),
      Utils.getBaseFormattedDate(endPeriod, Utils.longDateFormat),
    ]
  }

  static getBaseFormattedCurrentTimezonedDate (): TDateString {
    return Utils.getBaseFormattedDate(Utils.getTimezonedMoment())
  }
}
