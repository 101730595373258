<template>
  <div class="appointment-confirmation">
    <div
      v-if="moduleEnabled"
      class="appointment-confirmation__module-enabled"
    >
      <div class="appointment-confirmation__notification-checkboxes messages-settings-notification-checkbox">
        <m-switch
          :value="appointmentConfirmationSwitches.appointmentConfirmation"
          :active-text="t('whatsapp.switchTitles.appointmentConfirmation')"
          @change="setAppointmentConfirmationSwitchesConfirm($event)"
        />
        <m-switch
          v-if="appointmentConfirmationSwitches.appointmentConfirmation"
          :value="appointmentConfirmationSwitches.appointmentConfirmationDefault"
          :active-text="t('whatsapp.switchTitles.enableConfirmationAppointmentWindow')"
          @change="setAppointmentConfirmationSwitchesConfirmDefault($event)"
        />
      </div>

      <div
        v-if="appointmentConfirmationSwitches.appointmentConfirmation"
        class="appointment-confirmation__settings"
      >
        <messages-priorities
          v-if="messagesPriorities.length"
          :messages-priorities="messagesPriorities"
          @updatePriorities="setMessagesPriorities"
        />

        <whatsapp-timeout-settings
          :delivery-waiting-time="deliveryWaitingTime"
          :read-waiting-time="readWaitingTime"
          :delivery-waiting-time-variables="REMIND_DELIVERY_WAITING_TIME_VARIABLES"
          :read-waiting-time-variables="REMIND_READ_WAITING_TIME_VARIABLES"
          @updateDeliveryWaitingTime="setAppointmentConfirmationDeliveredWaitingTime"
          @updateReadWaitingTime="setAppointmentConfirmationReadWaitingTime"
        />

        <div class="appointment-confirmation__confirmation-template">
          <variables-editor
            :editor-value="smsTemplateValue"
            :editor-title="t('whatsapp.notificationTemplate')"
            :editor-title-clarification="t('whatsapp.notificationTemplateClarification')"
            :preview-request="fetchTemplatePreview"
            :variables-list="APPOINTMENT_CONFIRMATION_VARIABLES_LIST"
            :required="smsTemplateValueValidation"
            validator-name="smsTemplate"
            template-type="appointment_confirmation"
            @registerValidator="onRegisterValidator"
            @changeEditorValue="setAppointmentConfirmationSmsTemplate"
          />
        </div>

        <variables-editor-whatsapp
          v-if="isWabaActive"
          :editor-value="wabaTemplateText"
          :variables-list="APPOINTMENT_CONFIRMATION_VARIABLES_LIST"
          @changeWabaEditorValue="setWabaTemplateText"
        >
          <template #templateSelector>
            <m-select
              :value="wabaTemplateId"
              :items="wazzupTemplatesList"
              option-label="title"
              value-key="templateGuid"
              :placeholder="t('whatsapp.chooseValue')"
              @change="insertTemplate"
            />
          </template>
        </variables-editor-whatsapp>

        <between-text
          :title="t('whatsapp.dispatchTime')"
          :text-before="t('whatsapp.sendFor')"
          :text-after="t('whatsapp.hoursBeforeAppointment')"
        >
          <m-input
            :clearable="false"
            type="number"
            :precision="0"
            :min="MIN_APPOINTMENT_CONFIRMATION_BEFORE_TIME_IN_HOURS"
            :max="MAX_APPOINTMENT_CONFIRMATION_BEFORE_TIME_IN_HOURS"
            :value="sendBefore"
            @input="setAppointmentSendBefore"
          />
        </between-text>

        <between-text
          :text-before="t('whatsapp.sendAfterTime')"
          :text-after="t('whatsapp.hoursAfterAppointmentCreated')"
        >
          <m-input
            :clearable="false"
            type="number"
            :precision="0"
            :min="MIN_APPOINTMENT_CONFIRMATION_AFTER_TIME_IN_HOURS"
            :max="MAX_APPOINTMENT_CONFIRMATION_AFTER_TIME_IN_HOURS"
            :value="sendAfter"
            @input="setAppointmentSendAfter"
          />
        </between-text>

        <div class="remind-time-clarification explanation explanation_solo">
          {{ t('whatsapp.remindTimeClarification') }}
        </div>
      </div>

      <m-button
        template="save"
        @click="saveForm"
      />
    </div>

    <introduction
      v-else
      :introduction-text="introductionText"
      icon="fad fa-comments"
    />
  </div>
</template>

<script>
import WhatsappTimeoutSettings from '@/vue_apps/app_configuration/messages/pages/components/WhatsappTimeoutSettings.vue'
import VariablesEditor from '@/vue_present/VariablesEditor/VariablesEditor.vue'
import VariablesEditorWhatsapp from '@/vue_present/VariablesEditorWhatsapp/VariablesEditorWhatsapp.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import {
  PRIORITY_BUTTONS_ITEMS,
  REMIND_DELIVERY_WAITING_TIME_VARIABLES,
  REMIND_READ_WAITING_TIME_VARIABLES,
  MIN_APPOINTMENT_CONFIRMATION_BEFORE_TIME_IN_HOURS,
  MAX_APPOINTMENT_CONFIRMATION_BEFORE_TIME_IN_HOURS,
  MIN_APPOINTMENT_CONFIRMATION_AFTER_TIME_IN_HOURS,
  MAX_APPOINTMENT_CONFIRMATION_AFTER_TIME_IN_HOURS,
} from '@/vue_apps/app_configuration/messages/scripts/consts'
import {
  APPOINTMENT_CONFIRMATION_VARIABLES_LIST,
} from '@/vue_components/app_configuration/messages/_messages_configurations_base/consts/messageVariablesList'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { fetchTemplatePreview } from '@/vue_apps/app_configuration/messages/scripts/fetchTemplatePreview'
import { variablesParserWithChild } from '@/vue_present/VariablesEditor/consts/variables'
import { MMessagesConfigurationPresenter } from '@/_api/Deliveries/MMessagesConfigurationPresenter'
import { MWhatsappConfigurationsPresenter } from '@/_api/Deliveries/MWhatsappConfigurationsPresenter'
import MessagesPriorities
  from '@/vue_apps/app_configuration/messages/pages/components/MessagesPriorities/MessagesPriorities.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import Introduction from '@/vue_components/introduction/introduction.vue'
import BetweenText from '@/vue_apps/app_configuration/messages/pages/components/BetweenText.vue'
import {
  findAndExtractWabaTemplateTextWithVariablesLabels,
  extractWabaTemplateText,
  replaceVariablesLabelsByValues,
} from '@/vue_apps/app_configuration/messages/scripts/wabaHelpers'

const CURRENT_PRIORITY_TYPE = 'appointment_confirmation'

export default {
  name: 'AppointmentConfirmation',
  components: {
    Introduction,
    MSwitch,
    MessagesPriorities,
    MButton,
    MInput,
    MSelect,
    VariablesEditorWhatsapp,
    VariablesEditor,
    WhatsappTimeoutSettings,
    BetweenText,
  },

  mixins: [ValidationParentMixin],

  props: {
    whatsappEnabled: Boolean,
    notificationsModuleEnabled: Boolean,
  },

  data () {
    return {
      wabaTemplateText: '',
      PRIORITY_BUTTONS_ITEMS,
      APPOINTMENT_CONFIRMATION_VARIABLES_LIST,
      REMIND_DELIVERY_WAITING_TIME_VARIABLES,
      REMIND_READ_WAITING_TIME_VARIABLES,
      MIN_APPOINTMENT_CONFIRMATION_BEFORE_TIME_IN_HOURS,
      MAX_APPOINTMENT_CONFIRMATION_BEFORE_TIME_IN_HOURS,
      MIN_APPOINTMENT_CONFIRMATION_AFTER_TIME_IN_HOURS,
      MAX_APPOINTMENT_CONFIRMATION_AFTER_TIME_IN_HOURS,
      unwatchTemplateList: null,
      fetchTemplatePreview,
    }
  },

  computed: {
    ...mapGetters('messagesStore', {
      appointmentConfirmationSwitches: 'vxGetAppointmentConfirmationSwitches',
      appointmentConfirmationSmsTemplate: 'vxGetAppointmentConfirmationSmsTemplate',
      messagesPriorities: 'vxGetMessagesPriorities',
      sendBefore: 'vxGetAppointmentSendBefore',
      sendAfter: 'vxGetAppointmentSendAfter',
    }),

    ...mapGetters('messagesStore/whatsappConfigurationsStore', {
      wazzupTemplatesList: 'vxGetWazzupTemplatesList',
      appointmentConfirmationWabaTemplateId: 'vxGetAppointmentConfirmationWabaTemplateId',
      wabaTemplateVariables: 'vxGetAppointmentConfirmationWabaTemplateVariables',
      deliveryWaitingTime: 'vxGetAppointmentConfirmationDeliveredWaitingTime',
      readWaitingTime: 'vxGetAppointmentConfirmationReadWaitingTime',
      isWabaActive: 'vxIsWabaActive',
    }),

    moduleEnabled () {
      return this.notificationsModuleEnabled && gon.application.client_widget_enabled
    },

    introductionText () {
      return this.notificationsModuleEnabled
        ? t('introduction.client_widget')
        : t('introduction.messages')
    },

    smsTemplateValue () {
      return this.appointmentConfirmationSmsTemplate
    },

    wabaTemplateId () {
      return { templateGuid: this.appointmentConfirmationWabaTemplateId }
    },
  },

  created () {
    const baseRequest = this.vxFetchMessagesConfigurations()

    if (!this.whatsappEnabled) { return }

    const whatsappRequests = [
      this.fetchWazzupTemplatesList(),
      this.vxFetchWhatsappConfigurations(),
      this.vxFetchMessagesPriorities(CURRENT_PRIORITY_TYPE),
    ]

    Promise.all([baseRequest, ...whatsappRequests])
      .then(this.initWaba)
  },

  methods: {
    ...mapActions('messagesStore', {
      vxFetchMessagesConfigurations: 'vxFetchMessagesConfigurations',
      vxFetchMessagesPriorities: 'vxFetchMessagesPriorities',
    }),

    ...mapActions('messagesStore/whatsappConfigurationsStore', {
      fetchWazzupTemplatesList: 'vxFetchWazzupTemplatesList',
      vxFetchWhatsappConfigurations: 'vxFetchWhatsappConfigurations',
    }),

    ...mapMutations('messagesStore', {
      setAppointmentConfirmationSwitchesConfirm: 'vxSetAppointmentConfirmationSwitchesConfirmation',
      setAppointmentConfirmationSwitchesConfirmDefault: 'vxSetAppointmentConfirmationSwitchesConfirmationDefault',
      setAppointmentConfirmationSmsTemplate: 'vxSetAppointmentConfirmationSmsTemplate',
      setMessagesPriorities: 'vxSetMessagesConfigurationsPriorities',
      setAppointmentSendBefore: 'vxSetAppointmentSendBefore',
      setAppointmentSendAfter: 'vxSetAppointmentSendAfter',
    }),

    ...mapMutations('messagesStore/whatsappConfigurationsStore', {
      setAppointmentConfirmationWabaTemplateId: 'vxSetAppointmentConfirmationWabaTemplateId',
      setAppointmentConfirmationDeliveredWaitingTime: 'vxSetAppointmentConfirmationDeliveredWaitingTime',
      setAppointmentConfirmationReadWaitingTime: 'vxSetAppointmentConfirmationReadWaitingTime',
    }),

    setWabaTemplateText (value) {
      this.wabaTemplateText = value
    },

    initWaba () {
      if (!this.isWabaActive) { return }

      this.setInitialWabaTemplateText()
    },

    setInitialWabaTemplateText () {
      const initialWabaTemplateText = this.appointmentConfirmationWabaTemplateId
        ? findAndExtractWabaTemplateTextWithVariablesLabels({
          wabaTemplates: this.wazzupTemplatesList,
          templateGuid: this.appointmentConfirmationWabaTemplateId,
          templateVariablesValues: this.wabaTemplateVariables,
        })
        : ''

      this.setWabaTemplateText(initialWabaTemplateText)
    },

    insertTemplate (template) {
      const newWabaTemplateText = template
        ? extractWabaTemplateText(template)
        : ''

      this.setWabaTemplateText(newWabaTemplateText)
      this.setAppointmentConfirmationWabaTemplateId(template?.templateGuid)
    },

    smsTemplateValueValidation () {
      return this.smsTemplateValue
        ? this.smsTemplateValue.indexOf('appointment.confirmation_url') === -1
          ? [t('errors.missingVariableConfirmationUrl')]
          : []
        : [t('errors.filled?')]
    },

    saveForm () {
      if (this.hasErrors()) { return }

      const whatsappEditorValueToServer = replaceVariablesLabelsByValues(this.wabaTemplateText || '')

      const messagesConfigurations = {
        appointmentConfirmation: this.appointmentConfirmationSwitches.appointmentConfirmation,
        appointmentConfirmationDefault: this.appointmentConfirmationSwitches.appointmentConfirmationDefault,
        appointmentConfirmationTemplate: this.smsTemplateValue,
        appointmentConfirmationSendBeforeHours: this.sendBefore,
        appointmentConfirmationSendAfterHours: this.sendAfter,
      }
      const whatsappConfigurations = {
        appointmentConfirmationWabaTemplateVariables: variablesParserWithChild(whatsappEditorValueToServer),
        appointmentConfirmationWabaTemplateId: this.appointmentConfirmationWabaTemplateId,
        appointmentConfirmationDeliveredWaitingTime: this.deliveryWaitingTime,
        appointmentConfirmationReadWaitingTime: this.readWaitingTime,
      }
      const channelsPriorities = {
        priorityType: CURRENT_PRIORITY_TYPE,
        priorities: this.messagesPriorities,
      }

      const messagesConfigurationsPresenter = new MMessagesConfigurationPresenter()
      const whatsappConfigurationsPresenter = new MWhatsappConfigurationsPresenter()

      Promise.all([
        messagesConfigurationsPresenter.update(messagesConfigurations),
        messagesConfigurationsPresenter.savePriorities(channelsPriorities),
        whatsappConfigurationsPresenter.saveConfigurations(whatsappConfigurations),
      ])
    },
  },
}
</script>
