import { IPersonalSimpleMedicalAssessment } from '@/vue_apps/CheckupsModule/interfaces/IPersonalSimpleMedicalAssessment'
import {
  PersonalSimpleMedicalAssessmentModel,
} from '@/vue_apps/CheckupsModule/classes/PersonalSimpleMedicalAssessment/PersonalSimpleMedicalAssessmentModel'
import { getParsedDate } from '@/vue_apps/CheckupsModule/store/getParsedDate'

export const personalSimpleMedicalAssessmentAdapter = {
  toServer (data: PersonalSimpleMedicalAssessmentModel) {
    return {
      document: {
        titleDate: `${data.titleDate.year}-${data.titleDate.month}-${data.titleDate.day}`,
        workerFullName: data.workerFullName,
        workerAge: data.workerAge,
        workerSex: data.workerSex,
        companyTitle: data.companyTitle,
        workerDepartment: data.workerDepartment,
        workerPosition: data.workerPosition,
        factorsData: [data.factorsData],
        medicalAssessmentData: data.medicalAssessmentData,
        harmfulFactorsData: [data.harmfulFactorsData],
        healthGroupData: data.healthGroupData,
        footerName: data.footerName,
        footerDate: Utils.getBaseFormattedDate(data.footerDate, Utils.momentDateFormat),
      },
    }
  },

  toClient (data: IPersonalSimpleMedicalAssessment) {
    return {
      ...data,
      titleDate: getParsedDate(data.titleDate),

      headerClinicLicenseIssueDate: data.headerClinicLicenseIssueDate
        ? Utils.getFormattedDate(data.headerClinicLicenseIssueDate)
        : '',

      headerClinicPhone: data.headerClinicPhone
        ? `+${Services.phoneMask.setMask(data.headerClinicPhone)}`
        : '',

      factorsData: data.factorsData.join('\n'),
      harmfulFactorsData: data.harmfulFactorsData.join('\n'),
      footerDate: Utils.getFormattedDate(data.footerDate),
    }
  },
}
