<template>
  <div
    class="canny-feedback"
    :data-canny-token="onTokenChange"
  >
    <epic-spinner :visibility="isLoading" />
    <div :style="introductionVisibleStyles.intro">
      <div class="introduction-container">
        <div class="introduction-icon-container">
          <i class="fad fa-comments-alt" />
        </div>
        <div class="introduction-description-container">
          <div
            class="introduction-description"
            v-html="t('introduction.support_feedback')"
          />
        </div>
      </div>
    </div>

    <div
      :style="introductionVisibleStyles.feedback"
      data-canny
      class="canny-feedback__iframe"
    >
      <span
        v-if="errorMessage"
        class="canny-feedback__email-error"
      >
        <i class="fad fa-fw fa-exclamation-circle" />
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<script>
import { APP_ID, OBSERVER_CONFIG } from '../const/const'
import { loadCannySDK } from '../const/methods'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import { CANNY_FEEDBACK } from '@/vue_components/router/modules/support/route_names.js'

export default {
  name: 'CannyFeedback',
  components: {
    EpicSpinner,
  },

  props: {
    boardToken: {
      type: String,
      default: '',
    },

    ssoToken: {
      type: String,
      default: '',
    },

    ssoUser: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      isLoading: false,
      observer: null,
      firstRender: true,
      errorMessage: '',

      CANNY_FEEDBACK,
    }
  },

  computed: {
    /**
     * Управление рендером виджета
     * (watch не справляется с изменением boardToken)
     */
    onTokenChange () {
      /* eslint-disable */
      this.errorMessage = ''
      if (this.boardToken && !gon.application.current_user.email) {
        this.errorMessage = t('canny_feedback_required_email')

        return
      }

      // решает проблему получения куки при первом открытии
      if (this.firstRender && this.boardToken) {
        const that = this

        // eslint-disable-next-line vue/no-async-in-computed-properties
        setTimeout(() => { that.renderCannyBoard() }, 500)

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.firstRender = false

        return this.boardToken
      }

      this.$nextTick(() => {
        this.renderCannyBoard()
      })

      return this.boardToken
      /* eslint-enable */
    },

    /*
    * Нужно для того, чтобы iframe не отлетал, если перейти support/feedback
    * v-if успешно ломает iframe
    */
    introductionVisibleStyles () {
      if (this.$route.name === CANNY_FEEDBACK) {
        return {
          intro: { display: 'flex' },
          feedback: { display: 'none' },
        }
      }

      return {
        intro: { display: 'none' },
        feedback: { display: 'flex' },
      }
    },
  },

  created () {
    loadCannySDK()
    this.identifyUser()
    this.createObserver()
  },

  methods: {
    /**
     * Identify Users
     * https://developers.canny.io/install
     */
    identifyUser () {
      if (!this.ssoUser.email) { return }
      window.Canny('identify', { appID: APP_ID, user: this.ssoUser })
    },

    createObserver () {
      const that = this
      this.observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.addedNodes.length && mutation.addedNodes[0].id === 'canny-iframe') {
            document.querySelector('#canny-iframe').addEventListener('load', () => {
              that.isLoading = false
              this.observer.disconnect()
            })
          }
        })
      })
    },

    /**
     * Рендер виджета (доски)
     * https://developers.canny.io/install/widget/web
     */
    renderCannyBoard () {
      if (
        this.$route.name === CANNY_FEEDBACK ||
        !this.boardToken
      ) {
        return
      }

      this.isLoading = true
      this.observer.observe(document.querySelector('.canny-feedback__iframe'), OBSERVER_CONFIG())

      let basePath = this.$route.path
      if (this.$route.params.postId) {
        basePath = basePath.slice(0, basePath.indexOf('/p/'))
      }

      document.querySelectorAll('#canny-iframe').forEach((e) => e.parentNode.removeChild(e))
      window.Canny('render', {
        boardToken: this.boardToken,
        basePath,
        ssoToken: this.ssoToken,
      })
    },
  },
}
</script>
