<template>
  <div class="date-range-picker">
    <el-date-picker
      v-model="dateRangeProxy"
      v-bind="$attrs"
      :start-placeholder="startPlaceholder"
      :end-placeholder="endPlaceholder"
      type="daterange"
      :format="dateFormat"
      :value-format="valueFormat"
      :picker-options="pickerOptions"
      placement="bottom-start"
      v-on="$listeners"
    >
      <slot />
    </el-date-picker>
  </div>
</template>

<script>
import { CALENDAR_RANGE, DEFAULT_CALENDAR_RANGE_NAMES } from '@/vue_components/calendar_ranges.js'

export default {
  name: 'DateRangePicker',

  model: {
    prop: 'dateRange',
  },

  props: {
    dateRange: {
      type: Array,
      default: () => [],
    },

    startPlaceholder: {
      type: String,
      default: t('start'),
    },

    endPlaceholder: {
      type: String,
      default: t('end'),
    },

    fallbackValue: {
      type: Array,
      default: () => [],
    },

    applicationDateFormat: {
      type: String,
      default: '',
    },

    format: {
      type: String,
      default: '',
    },

    valueFormat: {
      type: String,
      default: '',
    },

    shortcuts: {
      type: Array,
      default: () => DEFAULT_CALENDAR_RANGE_NAMES,
    },

    firstDayOfWeek: {
      type: Number,
      default: gon.localization.day_week_start,
    },
  },

  computed: {
    pickerOptions () {
      return {
        shortcuts: this.shortcuts.map((shortcut) => CALENDAR_RANGE[shortcut]),
        firstDayOfWeek: this.firstDayOfWeek,
      }
    },

    dateFormat () {
      if (this.applicationDateFormat) {
        return this.applicationDateFormat
          .toLowerCase()
          .replace(/mm/, 'MM')
      }

      return this.format
    },

    dateRangeProxy: {
      set (range) {
        if (Array.isArray(range) && !this.valueFormat) {
          this.$emit('change', [
            moment(range[0]).hour(0).minutes(0).seconds(0).toDate(),
            moment(range[1]).hour(23).minutes(59).seconds(59).toDate(),
          ])
        } else {
          this.$emit('change', this.fallbackValue)
        }
      },
      get () {
        return this.dateRange
      },
    },
  },
}
</script>
