<template>
  <div
    ref="clinicLogotypes"
    class="clinic-logotypes"
  >
    <div class="max-file-size">
      {{ maxFileSizeMessage }}
    </div>
    <div
      v-if="logoExist !== null"
      class="logo-container"
    >
      <div class="title">
        {{ t('activerecord.attributes.clinic.logo') }}
      </div>
      <logotype
        v-if="logoExist"
        :logo-source="logoSource"
        :epic-spinner="showEpicSpinnerForLogotype"
        @input="uploadLogo"
        @delete="handleDeletingLogo"
      />
      <logotype-not-found
        v-else
        :epic-spinner="showEpicSpinnerForLogotype"
        @input="uploadLogo"
      />
    </div>

    <div class="logo-container">
      <div class="title">
        {{ t('activerecord.attributes.clinic.widget_clinic_logo') }}
      </div>

      <element-stub-wrapper
        :is-visible="isShowStubModal"
        :requirements="[
          { name: MODULES.CLIENT_WIDGET, isEnabled: GET_APP_CONF_CLIENT_WIDGET_MODULE_ENABLED },
          { name: OPTIONS.CLIENT_WIDGET_AVAILABLE, isEnabled: $security.canViewClientWidgetConfiguration },
        ]"
        :is-available-settings="false"
        @changeVisibility="$updateSync('isShowStubModal', $event)"
        @click="$emit('askShowStubModal')"
      >
        <widget-logotype
          :logo-is="widgetLogoIs"
          :logo-source="widgetLogoSource"
          :epic-spinner="showEpicSpinnerForWidgetLogotype"
          @cropped="uploadWidgetLogo"
          @delete="handleDeletingWidgetLogo"
        />
      </element-stub-wrapper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logotype from './logotype.vue'
import WidgetLogotype from './widget_logotype.vue'
import LogotypeNotFound from './logotype_not_found.vue'
import ElementStubWrapper from '@/vue_components/stubs/element_stub_wrapper'
import { MODULES, OPTIONS } from '@/specific/stubs/_helpers/constants'

export default {
  name: 'ClinicLogotypes',
  components: {
    Logotype,
    WidgetLogotype,
    LogotypeNotFound,
    ElementStubWrapper,
  },
  props: {
    clinicId: {
      type: Number,
      required: true,
    },
    isShowStubModal: Boolean,
  },
  data () {
    return {
      showEpicSpinnerForLogotype: false,
      showEpicSpinnerForWidgetLogotype: false,
      logoExist: null,
      widgetLogoIs: false,
      logoBase64: null,
      widgetLogoBase64: null,
      maxFileSize: gon.application.files.max_size,
      isDeleteWidgetLogo: false,
      MODULES,
      OPTIONS,
    }
  },
  computed: {
    ...mapGetters([
      'GET_APP_CONF_CLIENT_WIDGET_MODULE_ENABLED',
    ]),
    logoRoute () {
      return Routes.logo_clinic_path(this.clinicId)
    },
    widgetLogoRoute () {
      if (this.isDeleteWidgetLogo) {
        return Routes.widget_logo_clinic_path(this.clinicId, {
          version: 'original',
          uniq: +new Date(),
        })
      }

      return Routes.widget_logo_clinic_path(this.clinicId, { version: 'original' })
    },
    logoSource () {
      return this.logoBase64 || this.logoRoute
    },
    widgetLogoSource () {
      return this.widgetLogoBase64 || this.widgetLogoRoute
    },
    maxFileSizeToMb () {
      return Math.floor(this.maxFileSize / (1024 * 1024))
    },
    maxFileSizeMessage () {
      return `${t('max_file_size')} ${this.maxFileSizeToMb}  ${t('mbyte')}`
    },
  },
  watch: {
    clinicId () {
      this.isDeleteWidgetLogo = false
      this.logoBase64 = null
      this.widgetLogoBase64 = null

      this
        .checkLogoExistence()
        .then((bool) => {
          this.logoExist = bool
        })

      this
        .checkWidgetLogoExistence()
        .then((bool) => {
          this.widgetLogoIs = bool
        })
    },
  },
  mounted () {
    this
      .checkLogoExistence()
      .then((bool) => {
        this.logoExist = bool
      })

    this
      .checkWidgetLogoExistence()
      .then((bool) => {
        this.widgetLogoIs = bool
      })
  },
  methods: {
    checkWidgetLogoExistence () {
      return new Promise((resolve) => {
        $.ajax({
          url: this.$routes.widget_logo_exist_clinic_path(this.clinicId),
          type: 'GET',
          success () {
            resolve(true)
          },
          error () {
            resolve(false)
          },
        })
      })
    },

    checkLogoExistence () {
      return new Promise((resolve) => {
        $.ajax({
          url: this.$routes.logo_exist_clinic_path(this.clinicId),
          type: 'GET',
          success () {
            resolve(true)
          },
          error () {
            resolve(false)
          },
        })
      })
    },

    upload (data) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: this.$routes.clinic_path(this.clinicId),
          data,
          type: 'PATCH',
          processData: false,
          contentType: false,
          success: resolve,
          error: reject,
        })
      })
    },

    deleteWidgetLogo () {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: this.$routes.delete_widget_logo_clinic_path(this.clinicId),
          type: 'DELETE',
          success: (data) => resolve(data),
          error: (err) => reject(err),
        })
      })
    },

    deleteLogo () {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: this.$routes.delete_logo_clinic_path(this.clinicId),
          type: 'DELETE',
          success: (data) => resolve(data),
          error: (err) => reject(err),
        })
      })
    },

    handleDeletingLogo () {
      this.showEpicSpinnerForLogotype = true

      this.deleteLogo()
        .then(() => {
          Notificator.success(t('successful_logo_removal'))
          this.logoExist = false
          this.logoBase64 = null
        })
        .catch((err) => {
          console.error(err)
          Notificator.error(t('remove_logo_failed'))
        })
        .finally(() => {
          this.showEpicSpinnerForLogotype = false
        })
    },

    handleDeletingWidgetLogo () {
      this.showEpicSpinnerForWidgetLogotype = true

      this.deleteWidgetLogo()
        .then(() => {
          Notificator.success(t('successful_logo_removal'))
          this.isDeleteWidgetLogo = true

          this.widgetLogoBase64 = null
          this.widgetLogoIs = false
        })
        .catch((err) => {
          console.error(err)
          Notificator.error(t('remove_logo_failed'))
        })
        .finally(() => {
          this.showEpicSpinnerForWidgetLogotype = false
        })
    },

    uploadWidgetLogo (event) {
      const {
        file: { type, name },
        cropped,
      } = event

      cropped.toBlob((blob) => {
        const file = new File([blob], name, { type })

        if (file.size > this.maxFileSize) {
          Notificator.warning(Utils.maxFileSizeMessage())

          return
        }

        this.showEpicSpinnerForWidgetLogotype = true
        const formData = new FormData()
        formData.append('clinic[widget_logo]', file)

        this.upload(formData)
          .then(() => {
            Notificator.success(t('successful_logo_uploading'))
            this.widgetLogoBase64 = cropped.toDataURL()
            this.widgetLogoIs = true
          })
          .catch((err) => {
            let fileErorrs = t('unknown_error')

            if (err.responseJSON && err.responseJSON.file) {
              fileErorrs = err.responseJSON.file.join('.')
            }

            Notificator.error(`${t('upload_logo_failed')}: ${fileErorrs}`)
          })
          .finally(() => {
            this.showEpicSpinnerForWidgetLogotype = false
          })
      })
    },

    uploadLogo (event) {
      const file = event.target.files[0]

      if (file.size > this.maxFileSize) {
        Notificator.warning(Utils.maxFileSizeMessage())

        return
      }

      this.showEpicSpinnerForLogotype = true

      const fileReader = new FileReader()
      const formData = new FormData()
      formData.append('clinic[logo]', file)

      this.upload(formData)
        .then(() => {
          fileReader.onload = () => (this.logoBase64 = fileReader.result)
          fileReader.readAsDataURL(file)
          this.logoExist = true

          Notificator.success(t('successful_logo_uploading'))
        })
        .catch((err) => {
          let fileErorrs = t('unknown_error')

          if (err.responseJSON && err.responseJSON.file) {
            fileErorrs = err.responseJSON.file.join('.')
          }

          Notificator.error(`${t('upload_logo_failed')}: ${fileErorrs}`)
        })
        .finally(() => {
          this.showEpicSpinnerForLogotype = false
        })
    },
  },
}
</script>
