<template>
  <div
    ref="item"
    :class="['ctx-item', {underline}]"
  >
    <div
      ref="title"
      :class="['ctx-title', {active: highlighting}]"
      @mouseover="openSubmenu"
      @mouseout="highlighting = false"
      @click="emitSelect"
    >
      <slot name="title" />
      <span
        v-if="hasSubmenu"
        class="direction-ico"
      >
        <i class="fad fa-caret-right" />
      </span>
    </div>
    <div
      v-show="hasSubmenu && showSubmenu"
      ref="submenu"
      class="submenu"
      :style="[submenuCoords]"
    >
      <slot name="submenu" />
    </div>
  </div>
</template>

<script>
const OFFSET = 7

export default {
  name: 'CtxItem',
  props: {
    submenuEvent: {
      type: String,
      default: 'mouseover',
    },
    underline: Boolean,
  },
  data () {
    return {
      highlighting: false,
      showSubmenu: false,
      submenuCoords: { top: '0px', left: '0px' },
    }
  },
  computed: {
    hasSubmenu () {
      return !!this.$slots.submenu
    },
    neighboringCtxItems () {
      return this.$parent.$children.filter((child) => this !== child)
    },
  },
  beforeDestroy () {
    this.$pubSub.reset('CONTEXT_MENU:CLOSE')
  },
  mounted () {
    this.$pubSub.subscribe('CONTEXT_MENU:CLOSE', this.closeSubmenu)
  },
  methods: {
    openSubmenu () {
      this.highlight()
      this.$parent.setHighlighting(true)

      let clientX
      let clientY

      const fn = (e) => {
        clientX = e.clientX
        clientY = e.clientY
      }

      document.addEventListener('mousemove', fn)

      window.setTimeout(() => {
        const titleCoords = this.$refs.title.getBoundingClientRect()
        document.removeEventListener('mousemove', fn)

        if (
          (clientX >= titleCoords.left && clientX <= titleCoords.right) &&
            (clientY >= titleCoords.top && clientY <= titleCoords.bottom)
        ) {
          this.neighboringCtxItems.forEach((neigbor) => {
            neigbor.setShowSubmenu(false)
          })

          this._openSubmenu()
        }
      }, 300)
    },
    _openSubmenu () {
      const vm = this
      this.showSubmenu = true
      const screenWidth = document.documentElement.clientWidth
      const screenHeight = document.documentElement.clientHeight
      const itemCoords = vm.$refs.item.getBoundingClientRect()
      const freeSpaceOnRight = screenWidth - itemCoords.right
      const freeSpaceOnBottom = screenHeight - itemCoords.bottom

      this.$nextTick(() => {
        const submenuCoords = vm.$refs.submenu.getBoundingClientRect()

        if (freeSpaceOnRight >= submenuCoords.width) {
          vm.submenuCoords.left = itemCoords.right - OFFSET + 'px'
        } else {
          vm.submenuCoords.left = itemCoords.left - submenuCoords.width + OFFSET + 'px'
        }

        if (freeSpaceOnBottom >= submenuCoords.height) {
          vm.submenuCoords.top = itemCoords.top + OFFSET + 'px'
        } else {
          vm.submenuCoords.top = itemCoords.top - submenuCoords.height - OFFSET + 'px'
        }
      })
    },
    closeSubmenu () {
      this.showSubmenu = false
      this.highlighting = false
    },
    setHighlighting (bool) {
      this.highlighting = bool
    },
    highlight () {
      this.highlighting = true

      this.neighboringCtxItems.forEach((neigbor) => {
        neigbor.setHighlighting(false)
      })
    },
    setShowSubmenu (bool) {
      this.showSubmenu = bool
    },
    emitSelect (event) {
      if (this.hasSubmenu) return

      this.$emit('select', event)
      this.$pubSub.emitAsync('CONTEXT_MENU:CLOSE')
    },
  },
}
</script>
