<template>
  <epic-spinner :visibility="visibility" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'

export default defineComponent({
  name: 'EpicSpinnerApp',

  components: { EpicSpinner },

  data () {
    return {
      visibility: false,
      consumerStateMap: new Map(),
      consumerIdsGenerator: (function * () {
        let counter = 0
        while (true) yield ++counter
      })(),
    }
  },

  methods: {
    getConsumerId () {
      const id = this.consumerIdsGenerator.next().value
      this.consumerStateMap.set(id, false)

      return id
    },

    show (state, consumerId) {
      if (!consumerId) this.visibility = state
      else {
        this.consumerStateMap.set(consumerId, state)
        this.visibility = Array.from(this.consumerStateMap.values()).some((state) => state)
      }
    },
  },
})
</script>
