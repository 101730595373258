<template>
  <document-square-field-labeled class="no-gap justify-content-between">
    <template #label>
      <span>{{ t$('amountOfExpensesForMedicalServices', { serviceCode }) }}</span>
    </template>

    <template #field>
      <document-square-field-money
        :value="value"
        fill-blanks-at-end
      />
    </template>
  </document-square-field-labeled>
</template>

<script>
import DocumentSquareFieldLabeled
  from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareFieldLabeled/DocumentSquareFieldLabeled.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import DocumentSquareFieldMoney
  from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareFieldMoney/DocumentSquareFieldMoney.vue'

export default {
  name: 'TaxCertificate2024PrintableSum',

  components: { DocumentSquareFieldMoney, DocumentSquareFieldLabeled },

  mixins: [i18nScopeMixin],

  props: {
    serviceCode: { type: Number, required: true },
    value: { type: Number, default: 0 },
  },

  created () {
    this.setI18nScope('fixedDocument.taxCertificate2024')
  },
}
</script>
