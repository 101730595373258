import ApplicationNotificator from './notificator.vue'
import { createVueApp } from '@/vue_components/create_vue_app'

export default {
  init () {
    const container = document.createElement('div')
    container.id = 'notificator_area_block'
    window.document.body.appendChild(container)

    return createVueApp({
      el: '#notificator_area_block',
      render: (h) => h(ApplicationNotificator),
      useChildren: true,
    })
  },
}
