<template>
  <si-table
    class="egisz-cases-table"
    :not-found="casesList.length === 0"
  >
    <template #header>
      <si-field class="egisz-cases-table__header cases-col">
        <div class="cases-col cases-col_status" />

        <div class="cases-col cases-col_number">
          <span>{{ t('egisz.components.egisz_cases_table.id') }}</span>
        </div>

        <div class="cases-col cases-col_date">
          <span>{{ t('egisz.components.egisz_cases_table.date_open') }}</span>
        </div>

        <div class="cases-col cases-col_date">
          <span>{{ t('egisz.components.egisz_cases_table.date_closed') }}</span>
        </div>

        <div class="cases-col cases-col_episodes">
          <span>{{ t('egisz.components.egisz_cases_table.steps_count') }}</span>
        </div>

        <div class="cases-col cases-col_fio">
          <span>{{ t('egisz.components.egisz_cases_table.client_fio') }}</span>
        </div>

        <div class="cases-col cases-clinic">
          <span>{{ t('egisz.components.egisz_cases_table.clinic') }}</span>
        </div>

        <div class="cases-col cases-col_fio">
          <span>{{ t('egisz.components.egisz_cases_table.doctor_fio') }}</span>
        </div>
      </si-field>
    </template>

    <template #body>
      <si-field
        v-for="(medCase, index) in casesList"
        :key="index"
        class="egisz-clients-table__header clients-body"
        @click="$emit('selectMedCase', medCase)"
      >
        <div
          v-tooltip="t(`egisz.common_statuses.${medCase.closed && medCase.netrika_status}`)"
          class="cases-col cases-col_status"
        >
          <i
            class="font-size-17"
            :class="EGISZ_STATUSES_CASES_CSS(medCase.closed, medCase.netrika_status)"
          />
        </div>

        <div class="cases-col cases-col_number">
          <span>{{ medCase.id }}</span>
        </div>

        <div class="cases-col cases-col_date">
          <span>{{ medCase.opened_at_formatted }}</span>
        </div>

        <div class="cases-col cases-col_date">
          <span>{{ medCase.closed_at_formatted }}</span>
        </div>

        <div class="cases-col cases-col_episodes">
          <span>{{ medCase.steps_count }}</span>
        </div>

        <div class="cases-col cases-col_fio">
          <a
            :href="$routes.client_path(medCase.client_id)"
            :class="{ 'deleted': medCase.client_deleted_at }"
            target="_blank"
          >
            <span>{{ medCase.client_full_name }}</span>
          </a>
        </div>

        <div
          v-tooltip="medCase.clinic_title"
          class="cases-col cases-clinic"
        >
          <span>{{ medCase.clinic_title }}</span>
        </div>

        <div class="cases-col cases-col_fio">
          <span>{{ medCase.doctor_full_name }}</span>
        </div>
      </si-field>
    </template>
  </si-table>
</template>

<script>
import SiTable from '@/vue_components/sort_items/si_table/si_table'
import SiField from '@/vue_components/sort_items/si_table/si_field'
import { mapGetters } from 'vuex'
import { EGISZ_STATUSES_CASES_CSS } from '@/vue_components/egisz/egisz_module/const/egisz_statuses'

export default {
  name: 'EgiszCasesTable',
  components: {
    SiField,
    SiTable,
  },

  props: {
    casesList: {
      type: Array,
      required: true,
      default: () => [],
    },

    hasFilters: Boolean,

    loadingCasesIds: {
      type: Array,
      default: () => [],
    },

    canManage: Boolean,
  },

  data () {
    return {
      EGISZ_STATUSES_CASES_CSS,
    }
  },

  computed: {
    ...mapGetters(['GET_LOCALIZATION_DATE_FORMAT']),

    readOnly () {
      return !this.canManage
    },
  },
}
</script>
